import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

const footerStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  footerText: {
    color: 'white',
  },
  logo: {
    width: '30px',
    marginRight: '1rem',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    }
  },
}))

function Copyright() {
  const classes = footerStyles();
  return (
    <div className={classes.container}>
      <img className={classes.logo} src="/images/logo/emblem_white.svg" alt="Pockett logo in white" />
      <Typography variant="body2" component="div" className={classes.footerText} align="center">
        &copy;{' '}{new Date().getFullYear()}{' '}
        <Link className={classes.link} to="/">Pockett.</Link>
        {' '}All rights reserved.
      </Typography>
    </div>
  );
}

export default Copyright;