import { combineReducers } from 'redux';
import notifyReducer from './ducks/Notify/reducer';
import feedbackReducer from './ducks/Feedback/reducer';
import donationReducer from './ducks/Donation/reducer';
import blogReducer from './ducks/Blog/reducer';
import appStoreReducer from './ducks/AppStore/reducer';

export default combineReducers({
  appStore: appStoreReducer,
  notifyMe: notifyReducer,
  feedback: feedbackReducer,
  donation: donationReducer,
  blog: blogReducer,
});