import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Transition, TransitionGroup } from 'react-transition-group';
import Grow from '@mui/material/Grow';
import Zoom from '@mui/material/Zoom';
import { alpha } from '@mui/material/styles';
import {
  useMediaQuery, useTheme,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded';

import StyledButton from '../shared/Button/Button';
import WhiteTextTypography from '../shared/WhiteTextTypography';
import LazyImage from '../shared/LazyImage/LazyImage';
import FeaturesText from './FeaturesText';
import Ratings from '../shared/Ratings/Ratings';
//import { ReactComponent as HalftoneSvg } from '../../assets/svg/halftone_transition.svg';

const useStyles = makeStyles((theme) => ({
  heroTitle: {
    position: 'relative',
    marginBottom: theme.spacing(4),
  },
  phoneContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  phonesRow: {
    position: 'relative',
    width: '500px',
    height: '410px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '700px',
      height: '310px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '340px',
      height: '240px',
    },
  },
  phone1: {
    position: 'absolute',
    zIndex: 20,
    left: -35,
    [theme.breakpoints.between('sm', 'md')]: {
      left: 80,
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      height: '200px',
      left: 10,
    }
  },
  phone2: {
    position: 'absolute',
    zIndex: 22,
    left: 130,
    top: -20,
    height: '450px',
    [theme.breakpoints.between('sm', 'md')]: {
      left: 230,
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      height: '250px',
      left: 95,
    }
  },
  phone3: {
    position: 'absolute',
    zIndex: 21,
    left: 335,
    [theme.breakpoints.between('sm', 'md')]: {
      left: 430,
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      height: '200px',
      left: 200,
    }
  },
  featureContainer: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '1024px',
    [theme.breakpoints.up('xs')]: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  priceTierContainer: {
    display: 'flex',
    alignItems: 'stretch',
  },
  priceTier: {
    position: 'relative',
    borderRadius: theme.spacing(1),
    border: `solid 1px ${theme.palette.primary.main}`,
    borderTop: `solid ${theme.spacing(1)} ${theme.palette.primary.main}`,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
    cursor: 'pointer',
    color: '#5d747f',
    transition: 'filter,transform 0.4s',
    '&:hover': {
      transform: 'scale3d(1.05,1.05,1)',
      filter: `drop-shadow(0 10px 15px ${alpha(theme.palette.primary.light, 0.5)})`,
    },
    '&.free': {
      display: 'flex',
      border: 'solid 1px #555',
      borderTop: `solid ${theme.spacing(1)} #555`,
    },
    '& .tierTitle': {
      display: 'flex',
      fontWeight: 'bold',
      fontFamily: 'SFProDisplay-Bold',
      color: '#1f2e35',
    },
    '& .tierCaption': {
      position: 'absolute',
      top: '-20px',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: theme.palette.secondary.main,
      borderRadius: '6px',
      padding: `6px 13px`,
      fontSize: '0.7rem',
      fontFamily: 'SFProDisplay-Bold',
      color: theme.typography.h1.color,
    },
    '& .tierPrice': {
      display: 'flex',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      fontFamily: 'SFProDisplay-Bold',
      marginTop: theme.spacing(2),
      color: '#1f2e35',
    },
    '& .priceDuration': {
      fontSize: '0.85rem',
    },
    '& .pricingFeatureIncluding': {
      fontSize: '0.85rem',
      marginTop: theme.spacing(2),
      color: theme.typography.h1.color,
    },
    '& .MuiListItemText-primary': {
      color: '#5d747f',
    },
  },
  priceFeatureItem: {
    '& .MuiTypography-root': {
      color: theme.typography.body1.color,
    },
  },
  featureBlock: {
    justifyContent: 'center',
    maxWidth: theme.breakpoints.values.md,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  featureBullets: {
    marginBottom: theme.spacing(2),
  },
  appStoreContainer: {
    display: 'flex',
    '& a': {
      display: 'block',
      [theme.breakpoints.down('md')]: {
        marginTop: '1rem',
      },
    },
    justifyContent: 'center',
    padding: '2rem',
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    }
  },
  storeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0.5rem',
  },
  sectionTitle: {
    marginTop: '2.2rem',
    marginBottom: '1.5rem',
    textAlign: 'center',
  },
  featureInSection: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(20),
    },
  },
  featureAvatar: {
    width: '150px',
    height: '150px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    marginBottom: '1.1rem',
  },
  featureHeading: {
    color: '#5d747f',
    textTransform: 'uppercase',
    fontWeight: 800,
    marginBottom: '0.9rem',
  },
  featureParagraph: {
    marginTop: '1.3rem',
  },
  startTodayBtn: {
    padding: '1.2rem 2rem',
    textTransform: 'uppercase',
  },
  descriptionLeft: {
    display: 'flex',
    order: 1,
    [theme.breakpoints.between('xs', 'sm')]: {
      order: 2,
    },
  },
  featureImageRight: {
    display: 'flex',
    order: 2,
    //marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      order: 1,
    },
  },
  featureImageBackground: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    height: '40%',
    '& .featureImageBackgroundPolygon': {
      width: '100%',
      height: '100%',
      fill: theme.palette.primary.main,
    }
  },
  transitionCanvas: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '40%',
  },
  featureImageContainer: {
    position: 'relative',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    }
  },
  featureImage: {
    //position: 'absolute',
    //left: 0,
    //top: 0,
    zIndex: 30,
    transition: 'transform 0.4s ease-in-out, filter 0.4s ease-in-out',
    '&:hover': {
      transform: 'scale3d(1.05, 1.05, 1)',
      filter: 'drop-shadow(5px 5px 12px rgba(0, 0, 0, 0.5))',
    }
  },
  firstFeatureContainer: {
    alignItems: 'center',
  },
  playIconAvatar: {
    position: 'absolute',
    left: 'auto',
    top: 'auto',
    zIndex: 25,
    cursor: 'pointer',
    width: theme.spacing(10),
    height: theme.spacing(10),
    backgroundColor: theme.palette.primary.main,
    transition: 'all 0.2s ease-in',
    transform: 'translateZ(-1px)',
    '&:hover': {
      fontSize: '6rem',
      transform: 'translateZ(-1px), scale(1.2)',
      filter: `drop-shadow(0 10px 20px ${theme.palette.primary.main})`,
    }
  },
  playIcon: {
    fontSize: '4rem',
  },
  section: props => ({
    transition: 'transform 1s',
    minHeight: '800px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: props.color === undefined ? theme.palette.background.default : props.color,
    color: props.mode === 'light' ? '#1f2e35' : theme.palette.text.primary,
    overflow: 'hidden',
    position: 'relative',
    '@media screen and (max-width: 600px)': {
      '&': {
        padding: theme.spacing(2),
      },
    },
    '& .MuiTypography-root': {
      color: props.mode === 'light' ? '#1f2e35' : theme.palette.text.primary,
    },
    '& .MuiListItemText-primary': {
      color: props.mode === 'light' ? '#1f2e35' : theme.typography.body1.color,
    }
  }),
  miniSection: props => ({
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: props.color === undefined ? theme.palette.secondary.main : props.color,
    color: props.mode === 'dark' ? theme.palette.text.primary : '#1f2e35',
    '& .MuiTypography-root': {
      color: props.mode === 'dark' ? theme.palette.text.primary : '#1f2e35',
    }
  }),
  ffIcon: {
    marginRight: '1rem',
    fontSize: '4rem',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    }
  },
  moneyRuleRow: {
    maxWidth: theme.spacing(140),
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      maxWidth: theme.spacing(120),
    }
  },
  moneyRuleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(3),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    boxSizing: 'border-box',
    width: theme.spacing(32),
    height: theme.spacing(32),
    cursor: 'default',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(25),
      height: theme.spacing(25),
    },
    '& .caption': {
      position: 'absolute',
      bottom: '1.7rem',
      color: 'white',
      width: '100%',
      height: '50px',
      padding: `0 ${theme.spacing(2)}`,
      fontSize: '1rem',
      textAlign: 'center',
    },
  },
  ruleIcon: {
    width: 80,
    height: 130,
    '&:hover': {
      animation: 'rotateShake ease-out 0.2s 2 forwards',
    },
  },
  subtitleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '3rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  tabContainer: {
    padding: 0,
  },
  polygonWrapper: {
    position: 'absolute',
    //left: '25%',
    //top: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    //width: props => props.width,
    zIndex: '1',
    width: '370px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '250px',
    },
  },
  lineStroke: {
    animation: 'moveStrokes 40s linear forwards infinite',
  }
}));

const Section = React.forwardRef((props, ref) => {
  const { animate, color, ...other } = props;
  const classes = useStyles(props);

  return (
    <div ref={ref} className={classes.section} {...other}>
      { props.children }
    </div>
  );
});

const MiniSection = React.forwardRef((props, ref) => {
  const classes = useStyles(props);
  const {
    ...other
  } = props;

  return (
    <div className={classes.miniSection} {...other}>
      { props.children }
    </div>
  );
});

const ZoomTransition = React.forwardRef((props, ref) => {
  return <Zoom ref={ref} {...props} />;
});

//const topPosition = element => element.current == null ? 0 : element.current.getBoundingClientRect().top;

const freeFeatures = [
  'Unlimited accounts',
  'Income/expense transactions',
  'Customizable dashboard & widgets',
  'Cash flow calendar',
  'Monthly budget',
  'Saving goals',
  'Multicurrency support',
  '15 receipt scans per month',
];

const plusFeatures = [
  'Unlimited portfolios',
  'Multi-asset types (stocks, ETFs, mutual funds, forex, crypto)',
  'Dividend tracking',
  'Customizable portfolio widgets',
  'Intelligent portfolio analysis',
  'Daily performance tracking',
  'Export PDF reports',
];

const accountFeatures = [
  'Track all your accounts in one place',
  'See their totals in your preferred currency',
  'No more switching between bank apps or doing manual conversions!',
];

const dashboardFeatures = [
  'Easily track your net worth and financial habits over time',
  'View them all on a beautiful dashboard with customizable views and interactive widgets',
  'Discover trends in your spending across multiple accounts',
];

const widgetFeatures = [
  'Customize your widgets to fit your needs',
  'Use tags to track transactions across categories',
  'Create personalized views of your finances that make sense to you',
];

const calendarFeatures = [
  'Never forget what you spent on and when you spent it again',
  'Know when and where your money goes to figure out how to improve',
];

const budgetFeatures = [
  'Set a general budget for each month',
  'Allocate a budget for one or more categories',
  'Track the impact on your budget as you spend',
];

const goalFeatures = [
  'See exactly how much you need to save each month to reach your goals',
  'Allocate your savings towards each goal every month',
  'Track your progress toward your goals',
];

const portfolioFeatures = [
  'Track your investments across multiple brokers and exchanges',
  'See your portfolio holdings and overall gains in your preferred currency',
  'No more switching between different accounts, platforms or apps!'
];

const multiAssetFeatures = [
  'Add your stocks, ETFs, mutual funds, forex and crypto',
  'Get up-to-date prices and see what your assets are worth right now',
];

const dividendFeatures = [
  'Automatically track dividends you have collected',
  'See how dividends contribute to your total gains',
];

const investmentWidgetFeatures = [
  'Use a range of powerful and customizable widgets',
  'Analyze your portfolio across any time period',
  'Compare your portfolio performance with any benchmark or asset',
  'Identify your best investments so you can make better decisions',
];

const dailyPerformanceFeatures = [
  'Track your portfolio gains day over day and month over month',
  'See it all add up in your preferred currency',
];

const LandingPage = (props) => {
  const classes = useStyles();
  const [show, doShow] = useState({
    featureImage: false,
    features: false,
    connect: false,
    pricing: false,
    faq: false,
    download: false,
  });
  const featureImageRef = useRef(null),
    //featuresRef = useRef(null),
    connectRef = useRef(null),
    pricingRef = useRef(null),
    faqRef = useRef(null),
    downloadRef = useRef(null);
  /*const featureImagePos = topPosition(featureImageRef),
    //featuresPos = topPosition(featuresRef),
    connectPos = topPosition(connectRef),
    pricingPos = topPosition(pricingRef),
    faqPos = topPosition(faqRef),
    downloadPos = topPosition(downloadRef);*/

  const [isWebsiteVideoShown, setShowWebsiteVideo] = useState(false);
  const [accountTabValue, setAccountTab] = useState('0');
  const [portfolioTabValue, setPortfolioTab] = useState('0');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const phoneContainerRef = useRef(null);

  /*useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);*/

  /*const onScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    console.log('scrollPos', scrollPosition, featureImagePos, downloadPos);
    if (featureImagePos <= scrollPosition && scrollPosition < connectPos) {
      console.log('showing feature image');
      doShow(state => ({ ...state, featureImage: true }));
    } else if (featuresPos <= scrollPosition && scrollPosition < connectPos) {
      console.log('showing feature');
      doShow(state => ({ ...state, features: true }));
    } else if (connectPos < scrollPosition && scrollPosition < pricingPos) {
      console.log('showing connect');
      doShow(state => ({ ...state, connect: true }));
    } else if (pricingPos < scrollPosition && scrollPosition < faqPos) {
      console.log('showing pricing');
      doShow(state => ({ ...state, pricing: true }));
    } else if (faqPos < scrollPosition && scrollPosition < downloadPos) {
      console.log('showing faq');
      doShow(state => ({ ...state, faq: true }));
    } else if (downloadPos < scrollPosition) {
      console.log('showing download');
      doShow(state => ({ ...state, download: true }));
    }
  };*/

  const {
    fetchIOSRatings,
    fetchAndroidRatings,
    iosRatings,
    androidRatings,
  } = props;

  useEffect(() => {
    fetchIOSRatings();
    //fetchAndroidRatings();
  }, [fetchIOSRatings, fetchAndroidRatings]);

  const showWebsiteVideo = () => {
    setShowWebsiteVideo(true);
  };

  const closeWebsiteVideo = () => {
    setShowWebsiteVideo(false);
  };

  const handleAccountTabChange = (event, newValue) => {
    setAccountTab(newValue);
  };

  const handlePortfolioTabChange = (event, newValue) => {
    setPortfolioTab(newValue);
  };

  const defaultStyle = {
    transition: `transform 3000ms ease-in-out, opacity 3000ms ease-in-out`,
    position: 'relative',
  };

  const transitionStyles = {
    entering: { transform: 'none' },
    entered: (deg, index) => {
      return {
        transform: `perspective(30cm) translateZ(-50px) rotateY(${deg}deg)`,
        transformStyle: 'preserve-3d',
        zIndex: index,
      };
    },
    exiting: { transform: 'none' },
    exited: { opacity: 0 },
  };

  return (
    <div>
      <Section animate={show.featureImage} ref={featureImageRef} color="#1f2e35">
        <svg id="phoneBg" className={classes.featureImageBackground} height="800" width="100%" viewBox="0 0 1000 800" preserveAspectRatio="none">
          <line x1="0" y1="10" x2="1000" y2="250" className={classes.lineStroke} stroke={theme.palette.secondary.main} strokeWidth="15" strokeOpacity="75%" />
          <polygon className="featureImageBackgroundPolygon" points="0,10 1000,250 1000,800 0,800" />
        </svg>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.featureContainer}
        >
          <Grid item container sm={12} xs={12} justifyContent="center" flexDirection="column">
            <Typography
              variant="h1"
              color="textPrimary"
              className={classes.heroTitle}
              align="center"
            >
              Your personal finance companion
            </Typography>
            <div className={classes.subtitleRow}>
              <Typography variant="body1" align="center">
                Track all your
              </Typography>
              <FeaturesText features={['money', 'accounts', 'budgets', 'investments', 'stocks']} />
              <Typography variant="body1" align="center">
                in one app.
              </Typography>
            </div>
          </Grid>
          <Grid item sm={12} xs={12} className={classes.phoneContainer}>
            { !isWebsiteVideoShown && <div className={clsx(classes.featureImageContainer, classes.firstFeatureContainer)}>
              <div className={classes.phonesRow} ref={phoneContainerRef}>
                <TransitionGroup>
                  <Transition in={true} timeout={3000}>
                    {state => (
                      <div style={{...defaultStyle, ...transitionStyles[state](10, 20) }}>
                        <Grow
                          in={true}
                          ref={phoneContainerRef.current}
                          timeout={3000}
                        >
                          <img
                            className={classes.phone1}
                            src="images/phone_dashboard.svg"
                            width={isDesktop ? 200 : 130}
                            height={isDesktop ? 410 : 300}
                            alt="iPhone showing dashboard screen"
                          />
                        </Grow>
                      </div>  
                    )}
                  </Transition>
                </TransitionGroup>
                <TransitionGroup>
                  <Transition in={true} timeout={3000}>
                    {state => (
                      <div style={{...defaultStyle, ...transitionStyles[state](0, 23), transform: 'translateZ(-2px)' }}>
                        <Zoom in={true} ref={phoneContainerRef.current} timeout={2000}>
                          <img
                            className={classes.phone2}
                            src="images/phone_home.svg"
                            width={isDesktop ? 230 : 150}
                            height={isDesktop ? 410 : 180}
                            alt="iPhone showing Pockett summary screen"
                          />
                        </Zoom>
                      </div>
                    )}
                  </Transition>
                </TransitionGroup>
                <TransitionGroup>
                  <Transition in={true} timeout={3000}>
                    {state => (
                      <div style={{...defaultStyle, ...transitionStyles[state](-10, 21) }}>
                        <Grow
                          in={true}
                          ref={phoneContainerRef.current}
                          timeout={3000}
                        >
                          <img
                            className={classes.phone3}
                            src="images/phone_portfolio.svg"
                            width={isDesktop ? 200 : 130}
                            height={isDesktop ? 410 : 300}
                            alt="iPhone showing portfolio screen"
                          />
                        </Grow>
                      </div>  
                    )}
                  </Transition>
                </TransitionGroup>
              </div>
              <Avatar className={classes.playIconAvatar}>
                <PlayArrowIcon className={classes.playIcon} onClick={showWebsiteVideo} />
              </Avatar>
            </div>}
          </Grid>
        </Grid>
        <Dialog
          open={isWebsiteVideoShown}
          onClose={closeWebsiteVideo}
          TransitionComponent={ZoomTransition}
          fullWidth
          maxWidth='lg'
          aria-labelledby="website-video-modal"
        >
          <DialogTitle sx={{ padding: 0 }}>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={closeWebsiteVideo}
              size="large">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <div id="website-modal" style={{ display: 'flex', justifyContent: 'center' }}>
              <iframe width="1200" height="630" src="https://www.youtube-nocookie.com/embed/pYtF0F-bEBc"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen style={{ overflow: 'hidden' }}>
              </iframe>
            </div>
          </DialogContent>
        </Dialog>
      </Section>
      {/*<MiniSection>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FastForwardRoundedIcon color="primary" fontSize={isDesktop ? 'large' : 'small' } className={classes.ffIcon} />
          <Typography variant={isDesktop ? "h6" : 'body1'}>Simple. Effortless. Beautiful.</Typography>
        </div>
      </MiniSection>*/}
      <Section animate={show.connect} ref={connectRef}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.sectionTitle}
        >
          Built for your <Typography variant="inherit" color="primary" component="span">everyday finance needs</Typography>
        </Typography>
        <Box sx={{height: '15px'}}/>
        <Container maxWidth="md" className={classes.tabContainer}>
          <TabContext value={accountTabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                value={accountTabValue}
                onChange={handleAccountTabChange}
                aria-label="Free features"
              >
                <Tab label="Accounts" value="0" />
                <Tab label="Dashboard" value="1" />
                <Tab label="Widgets" value="2" />
                <Tab label="Calendar" value="3" />
                <Tab label="Budget" value="4" />
                <Tab label="Goals" value="5" />
              </Tabs>
            </Box>
            <TabPanel value="0">
              <Container maxWidth="md">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.featureInSection}
                >
                  <Grid item xs container direction="column" className={classes.descriptionLeft}>
                    <Typography variant="h6" color="textPrimary">Manage your accounts, in any currency</Typography>
                    <div className={classes.featureParagraph}>
                    {accountFeatures.map((feature, i) => (
                      <ListItem key={`accountFeature-${i}`} disableGutters dense>
                        <ListItemIcon sx={{ minWidth: '32px' }}>
                          <CheckCircleIcon fontSize="small" color="primary" />
                        </ListItemIcon>
                        <ListItemText>{feature}</ListItemText>
                      </ListItem>
                    ))}
                    </div>
                  </Grid>
                  <Grid item xs className={classes.featureImageRight}>
                    <div className={classes.featureImageContainer}>
                      <img
                        src="images/polygon_blue.svg"
                        className={classes.polygonWrapper}
                        alt="polygon"
                      />
                      <LazyImage
                        className={classes.featureImage}
                        src="images/accounts.svg"
                        height={isDesktop ? 360 : 230}
                        style={{ marginBottom: isDesktop ? 0 : theme.spacing(3) }}
                        alt="Account cards"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
            <TabPanel value="1">
              <Container maxWidth="md">
                <Grid container justifyContent="center" alignItems="center" className={classes.featureInSection}>
                  <Grid item xs container direction="column" className={classes.descriptionLeft}>
                    <Typography variant="h6" color="textPrimary">See all your money at a glance</Typography>
                    <div className={classes.featureParagraph}>
                      {dashboardFeatures.map((feature, i) => (
                        <ListItem key={`dashboardFeature-${i}`} disableGutters dense>
                          <ListItemIcon sx={{ minWidth: '32px' }}>
                            <CheckCircleIcon fontSize="small" color="primary" />
                          </ListItemIcon>
                          <ListItemText>{feature}</ListItemText>
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                  <Grid item xs className={classes.featureImageRight}>
                    <div className={classes.featureImageContainer}>
                      <img
                        src="images/polygon_blue.svg"
                        className={classes.polygonWrapper}
                        alt="polygon"
                      />
                      <LazyImage
                        className={classes.featureImage}
                        src="images/dashboard.svg"
                        width={isDesktop ? 250 : 220}
                        height={500}
                        alt="Dashboard"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
            <TabPanel value="2">
              <Container maxWidth="md">
                <Grid container justifyContent="center" alignItems="center" className={classes.featureInSection}>
                  <Grid item xs container direction="column" className={classes.descriptionLeft}>
                    <Typography variant="h6" color="textPrimary">Make the dashboard your own</Typography>
                    <div className={classes.featureParagraph}>
                      {widgetFeatures.map((feature, i) => (
                        <ListItem key={`widgetFeature-${i}`} disableGutters dense>
                          <ListItemIcon sx={{ minWidth: '32px' }}>
                            <CheckCircleIcon fontSize="small" color="primary" />
                          </ListItemIcon>
                          <ListItemText>{feature}</ListItemText>
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                  <Grid item xs className={classes.featureImageRight}>
                    <div className={classes.featureImageContainer}>
                      <img
                        src="images/polygon_blue.svg"
                        className={classes.polygonWrapper}
                        alt="polygon"
                      />
                      <LazyImage
                        className={classes.featureImage}
                        src="images/widgets.svg"
                        width={isDesktop ? 420 : 270}
                        alt="Widgets"
                        style={{ marginBottom: '3rem' }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
            <TabPanel value="3">
              <Container maxWidth="md">
                <Grid container justifyContent="center" alignItems="center" className={classes.featureInSection}>
                  <Grid item xs container direction="column" className={classes.descriptionLeft}>
                    <Typography variant="h6" color="textPrimary">See when and where every penny goes</Typography>
                    <div className={classes.featureParagraph}>
                      {calendarFeatures.map((feature, i) => (
                        <ListItem key={`calendarFeature-${i}`} disableGutters dense>
                          <ListItemIcon sx={{ minWidth: '32px' }}>
                            <CheckCircleIcon fontSize="small" color="primary" />
                          </ListItemIcon>
                          <ListItemText>{feature}</ListItemText>
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                  <Grid item xs className={classes.featureImageRight}>
                    <div className={classes.featureImageContainer}>
                      <img
                        src="images/polygon_blue.svg"
                        className={classes.polygonWrapper}
                        alt="polygon"
                      />
                      <LazyImage
                        className={classes.featureImage}
                        src="images/calendar.svg"
                        width={isDesktop ? 280 : 250}
                        alt="Cashflow Calendar"
                        style={{ marginBottom: isDesktop ? 0 : theme.spacing(3) }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
            <TabPanel value="4">
              <Container maxWidth="md">
                <Grid container justifyContent="center" alignItems="center" className={classes.featureInSection}>
                  <Grid item xs container direction="column" className={classes.descriptionLeft}>
                    <Typography variant="h6" color="textPrimary">Keep track of your spending with a monthly budget</Typography>
                    <div className={classes.featureParagraph}>
                      {budgetFeatures.map((feature, i) => (
                        <ListItem key={`budgetFeature-${i}`} disableGutters dense>
                          <ListItemIcon sx={{ minWidth: '32px' }}>
                            <CheckCircleIcon fontSize="small" color="primary" />
                          </ListItemIcon>
                          <ListItemText>{feature}</ListItemText>
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                  <Grid item xs className={classes.featureImageRight}>
                    <div className={classes.featureImageContainer}>
                      <img
                        src="images/polygon_blue.svg"
                        className={classes.polygonWrapper}
                        alt="polygon"
                      />
                      <LazyImage
                        className={classes.featureImage}
                        src="images/budget.svg"
                        width={isDesktop ? 320 : 250}
                        style={{ marginBottom: isDesktop ? 0 : theme.spacing(3) }}
                        alt="Budget"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
            <TabPanel value="5">
              <Container maxWidth="md">
                <Grid container justifyContent="center" alignItems="center" className={classes.featureInSection}>
                  <Grid item xs container direction="column" className={classes.descriptionLeft}>
                    <Typography variant="h6" color="textPrimary">Stay focused on your financial goals</Typography>
                    <div className={classes.featureParagraph}>
                      {goalFeatures.map((feature, i) => (
                        <ListItem key={`goalFeature-${i}`} disableGutters dense>
                          <ListItemIcon sx={{ minWidth: '32px' }}>
                            <CheckCircleIcon fontSize="small" color="primary" />
                          </ListItemIcon>
                          <ListItemText>{feature}</ListItemText>
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                  <Grid item xs className={classes.featureImageRight}>
                    <div className={classes.featureImageContainer}>
                      <img
                        src="images/polygon_blue.svg"
                        className={classes.polygonWrapper}
                        alt="polygon"
                      />
                      <LazyImage
                        className={classes.featureImage}
                        src="images/goals.svg"
                        width={isDesktop ? 320 : 250}
                        style={{ marginBottom: isDesktop ? 0 : theme.spacing(3) }}
                        alt="Goals"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
          </TabContext>
        </Container>
      </Section>
      <Section animate={show.connect} ref={connectRef}>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.sectionTitle}
        >
          And your <Typography variant="inherit" color="primary" component="span">investing journey</Typography>
        </Typography>
        <div style={{height: '15px'}}/>
        <Container maxWidth="md" className={classes.tabContainer}>
          <TabContext value={portfolioTabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                value={portfolioTabValue}
                onChange={handlePortfolioTabChange}
                aria-label="Plus features"
                textColor="secondary"
                indicatorColor="secondary"
              >
                <Tab label="All-in-one portfolio" value="0" />
                <Tab label="Multi-asset types" value="1" />
                <Tab label="Dividend tracking" value="2" />
                <Tab label="Intelligent insights" value="3" />
                <Tab label="Daily performance" value="4" />
              </Tabs>
            </Box>
            <TabPanel value="0">
              <Container maxWidth="md">
                <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.featureInSection}>
                  <Grid item xs>
                    <div className={classes.featureImageContainer}>
                      <img
                        src="images/polygon_yellow.svg"
                        className={classes.polygonWrapper}
                        alt="polygon"
                      />
                      <LazyImage
                        className={classes.featureImage}
                        src="images/portfolio_main.svg"
                        width={isDesktop ? 250 : 220}
                        style={{ marginBottom: isDesktop ? 0 : theme.spacing(3) }}
                        alt="Set budgets and goals"
                      />
                    </div>
                  </Grid>
                  <Grid item xs container direction="column">
                    <Typography variant="h6" color="textPrimary">See your entire investment portfolio</Typography>
                    <div className={classes.featureParagraph}>
                      {portfolioFeatures.map((feature, i) => (
                        <ListItem key={`goalFeature-${i}`} disableGutters dense>
                          <ListItemIcon sx={{ minWidth: '32px' }}>
                            <CheckCircleIcon fontSize="small" color="secondary" />
                          </ListItemIcon>
                          <ListItemText>{feature}</ListItemText>
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
            <TabPanel value="1">
              <Container maxWidth="md">
                <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.featureInSection}>
                  <Grid item xs>
                    <div className={classes.featureImageContainer}>
                      <img
                        src="images/polygon_yellow.svg"
                        className={classes.polygonWrapper}
                        alt="polygon"
                      />
                      <LazyImage
                        className={classes.featureImage}
                        src="images/multiassets.svg"
                        width={isDesktop ? 300 : 250}
                        style={{ marginBottom: isDesktop ? 0 : theme.spacing(3) }}
                        alt="Track multiple assets"
                      />
                    </div>
                  </Grid>
                  <Grid item xs container direction="column">
                    <Typography variant="h6" color="textPrimary">Track assets from over 70 exchanges worldwide</Typography>
                    <div className={classes.featureParagraph}>
                      {multiAssetFeatures.map((feature, i) => (
                        <ListItem key={`multiAssetFeature-${i}`} disableGutters dense>
                          <ListItemIcon sx={{ minWidth: '32px' }}>
                            <CheckCircleIcon fontSize="small" color="secondary" />
                          </ListItemIcon>
                          <ListItemText>{feature}</ListItemText>
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
            <TabPanel value="2">
              <Container maxWidth="md">
                <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.featureInSection}>
                  <Grid item xs>
                    <div className={classes.featureImageContainer}>
                      <img
                        src="images/polygon_yellow.svg"
                        className={classes.polygonWrapper}
                        alt="polygon"
                      />
                      <LazyImage
                        className={classes.featureImage}
                        src="images/calendar_dividend.svg"
                        width={isDesktop ? 250 : 200}
                        style={{ marginBottom: isDesktop ? 0 : theme.spacing(3) }}
                        alt="Track dividends in portfolio calendar"
                      />
                    </div>
                  </Grid>
                  <Grid item xs container direction="column">
                    <Typography variant="h6" color="textPrimary">Track dividends effortlessly</Typography>
                    <div className={classes.featureParagraph}>
                      {dividendFeatures.map((feature, i) => (
                        <ListItem key={`dividendFeature-${i}`} disableGutters dense>
                          <ListItemIcon sx={{ minWidth: '32px' }}>
                            <CheckCircleIcon fontSize="small" color="secondary" />
                          </ListItemIcon>
                          <ListItemText>{feature}</ListItemText>
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
            <TabPanel value="3">
              <Container maxWidth="md">
                <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.featureInSection}>
                  <Grid item xs>
                    <div className={classes.featureImageContainer}>
                      <img
                        src="images/polygon_yellow.svg"
                        className={classes.polygonWrapper}
                        alt="polygon"
                      />
                      <LazyImage
                        className={classes.featureImage}
                        src="images/investment_widgets.svg"
                        width={isDesktop ? 250 : 200}
                        style={{ marginBottom: isDesktop ? 0 : theme.spacing(3) }}
                        alt="Investment widgets"
                      />
                    </div>
                  </Grid>
                  <Grid item xs container direction="column">
                    <Typography variant="h6" color="textPrimary">Get intelligent insights into your portfolio performance</Typography>
                    <div className={classes.featureParagraph}>
                      {investmentWidgetFeatures.map((feature, i) => (
                        <ListItem key={`investmentWidgetFeature-${i}`} disableGutters dense>
                          <ListItemIcon sx={{ minWidth: '32px' }}>
                            <CheckCircleIcon fontSize="small" color="secondary" />
                          </ListItemIcon>
                          <ListItemText>{feature}</ListItemText>
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
            <TabPanel value="4">
              <Container maxWidth="md">
                <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.featureInSection}>
                  <Grid item xs>
                    <div className={classes.featureImageContainer}>
                      <img
                        src="images/polygon_yellow.svg"
                        className={classes.polygonWrapper}
                        alt="polygon"
                      />
                      <LazyImage
                        className={classes.featureImage}
                        src="images/calendar_dailygain.svg"
                        width={isDesktop ? 250 : 230}
                        style={{ marginBottom: isDesktop ? 0 : theme.spacing(3) }}
                        alt="Track daily gains in portfolio calendar"
                      />
                    </div>
                  </Grid>
                  <Grid item xs container direction="column">
                    <Typography variant="h6" color="textPrimary">Track daily changes to your portfolio</Typography>
                    <div className={classes.featureParagraph}>
                      {dailyPerformanceFeatures.map((feature, i) => (
                        <ListItem key={`dailyPerformanceFeature-${i}`} disableGutters dense>
                          <ListItemIcon sx={{ minWidth: '32px' }}>
                            <CheckCircleIcon fontSize="small" color="secondary" />
                          </ListItemIcon>
                          <ListItemText>{feature}</ListItemText>
                        </ListItem>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </TabPanel>
          </TabContext>
        </Container>
      </Section>
      <Section>
        <Typography
          variant="h3"
          color="textPrimary"
          className={classes.sectionTitle}
        >
          Your money, your rules
        </Typography>
        <div style={{height: '15px'}}/>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          alignItems="center"
          className={classes.moneyRuleRow}
        >
          <Grid item xs={12} md={3} container justifyContent="center">
            <div className={classes.moneyRuleContainer}>
              <div className={classes.ruleIcon}>
                <LazyImage src="images/currencies_logo.svg" />
              </div>
              <div className="caption">Manage multiple currencies</div>
            </div>
          </Grid>
          <Grid item xs={12} md={3} container justifyContent="center">
            <div className={classes.moneyRuleContainer}>
              <div className={classes.ruleIcon}>
                <LazyImage src="images/unlimited_logo.svg" />
              </div>
              <div className="caption">Track unlimited accounts & portfolios</div>
            </div>
          </Grid>
          <Grid item xs={12} md={3} container justifyContent="center">
            <div className={classes.moneyRuleContainer}>
              <div className={classes.ruleIcon}>
                <LazyImage src="images/customize_logo.svg" />
              </div>
              <div className="caption">Customize everything to your liking</div>
            </div>
          </Grid>
        </Grid>
      </Section>
      <MiniSection>
        <Container maxWidth="md" className={classes.featureBlock}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item sm={12} container direction="column" xs={12}>
              <Typography variant="h3" style={{ marginTop: '2rem' }} align="center" gutterBottom>Your data, 100% owned by you.</Typography>
              <Typography paragraph style={{ marginBottom: '2rem' }} align="center">
              All your financial data are encrypted and stored locally on your device. This means that no one else has access
              to your data but you.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </MiniSection>
      <Section animate={show.pricing} ref={pricingRef} color="white" mode="light">
        <Typography variant="h2" className={classes.sectionTitle}>Pricing</Typography>
        <Typography align="center">Many of Pockett's features are <strong>free</strong> to use.</Typography>
        <Typography align="center">You can track unlimited accounts, income, expenses, budget and goals.</Typography>
        <div style={{ marginTop: '2.2rem', marginBottom: '2.2rem' }}>
          <StyledButton classes={{ primary: classes.startTodayBtn }} to="/#downloadSection">Start today for free</StyledButton>
        </div>
        <Typography paragraph align="center">If you need investment tracking features, you can upgrade to <strong>Pockett Plus</strong> at any time.</Typography>
        <Typography paragraph align="center">The Pockett Plus subscription has two options:<br />
        <strong>$3.99 monthly</strong>, which comes with a <strong>one week</strong> free trial<br />
        <strong>$35.99 yearly</strong>, which has a <strong>two weeks</strong> free trial
        </Typography>

        <Typography paragraph align="center" sx={{ marginBottom: '3rem' }}>Prices may vary based on your region due to local tax laws and conversion rates from U.S. dollars.</Typography>

        <Container className={classes.featureBlock} maxWidth="md" sx={{ marginBottom: theme.spacing(3) }}>
          <Grid container justifyContent="center" alignItems="stretch" spacing={2}>
            <Grid item xs className={classes.priceTierContainer}>
              <div className={clsx(classes.priceTier, 'free')}>
                <div className="tierTitle">Basic</div>
                <div className="tierPrice">Free</div>
                <div className="priceDuration">forever</div>
                <List dense>
                  {freeFeatures.map((feature, i) => (
                    <ListItem key={`freeFeature-${i}`}>
                      <ListItemIcon sx={{ minWidth: '32px' }}>
                        <CheckCircleIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText className={classes.priceFeatureItem}>{feature}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Grid>
            <Grid item xs className={classes.priceTierContainer}>
              <div className={classes.priceTier}>
                <div className="tierCaption">Save 25%</div>
                <div className="tierTitle">Plus (Yearly)</div>
                <div className="tierPrice">$3.00</div>
                <div className="priceDuration">per month</div>
                <Box sx={{ fontSize: '10px' }}>(billed as $35.99 per year)</Box>
                <div className="pricingFeatureIncluding">Everything in basic plus...</div>
                <List dense>
                  {plusFeatures.map((feature, i) => (
                    <ListItem key={`plusFeatureY-${i}`}>
                      <ListItemIcon sx={{ minWidth: '32px' }}>
                        <CheckCircleIcon fontSize="small" color="primary" />
                      </ListItemIcon>
                      <ListItemText className={classes.priceFeatureItem}>{feature}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Grid>
            <Grid item xs className={classes.priceTierContainer}>
              <div className={classes.priceTier}>
                <div className="tierTitle">Plus (Monthly)</div>
                <div className="tierPrice">$3.99</div>
                <div className="priceDuration">per month</div>
                <div className="pricingFeatureIncluding">Everything in basic plus...</div>
                <List dense>
                  {plusFeatures.map((feature, i) => (
                    <ListItem key={`plusFeatureM-${i}`}>
                      <ListItemIcon sx={{ minWidth: '32px' }}>
                        <CheckCircleIcon fontSize="small" color="primary" />
                      </ListItemIcon>
                      <ListItemText className={classes.priceFeatureItem}>{feature}</ListItemText>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Section>
      <Section animate={show.faq} ref={faqRef}>
        <Typography
          variant="h2"
          className={classes.sectionTitle}
          color="textPrimary"
          align="center"
        >
          Frequently asked questions
        </Typography>
        <Container maxWidth="md">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
              <Typography style={{ fontWeight: 'bolder' }}>
                Pockett used to be entirely free, why are you charging now?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <Typography paragraph>
                    Pockett started as a personal project <Link href="/ourstory">(read our story here)</Link>. We decided to share it freely as we believe it
                    will be a great personal finance companion for everyone. Since then, we've added many new features to Pockett
                    based on valuable feedback from our users. Implementation of some of these features, however, led to a significant
                    increase in our operating costs. We have thus introduced a paid plan for access to portfolio tracking features
                    in order to help cover these additional costs. We hope that this will help to keep Pockett sustainable, and
                    allow us to breakeven on our monthly operating costs at some point.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph>
                    Pockett's original features for tracking expenses, income, budget, and goals remain absolutely FREE to use, and
                    will continue to remain free.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography style={{ fontWeight: 'bolder' }}>
                How is Pockett different from other personal finance apps?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <Typography paragraph>
                    Pockett's dashboard, charts and widgets are highly customizable which allows you to slice and dice your financial data
                    in any way you like. This was the primary reason why we created Pockett - we simply weren't satisfied with the default
                    charts and limited options provided by the other apps.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph>
                    Unlike most personal finance apps, Pockett helps you track your money without ever storing your financial data. All the
                    data that you see in the app are stored locally on your device. This ensures that your data always remains private to
                    you and gives you complete control over how your sensitive financial information is used.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion style={{ marginBottom: theme.spacing(2) }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography style={{ fontWeight: 'bolder' }}>Is my information safe in Pockett?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <Typography paragraph>
                    Your privacy is our top priority.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph>
                    All your financial data are AES-256 encrypted and stored locally on your device. In other words, We do not store any of
                    your financial data on our end, and no one else has access to your data but you.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography paragraph>
                    Access to the app can be further protected by your biometrics or a password to ensure that your data always remains
                    private to you. To turn on password or biometric security, go to account settings.
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Container>
      </Section>
      <Section id="downloadSection" animate={show.download} ref={downloadRef} color="#30444e">
        <WhiteTextTypography
          variant="h2"
          align="center"
          style={{ margin: '0 0.3rem' }}
          gutterBottom
        >
          Pockett is available for iPhone and Android
        </WhiteTextTypography>
        <WhiteTextTypography variant="subtitle2" align="center" gutterBottom style={{ margin: '2rem 0.5rem 0 0.5rem' }}>
          Download the Pockett app today. It's free and simple to set up!
        </WhiteTextTypography>
        <div style={{ height: '30px' }} />
        <div className={classes.appStoreContainer}>
          <div className={classes.storeInfoContainer}>
            <Link href="https://apps.apple.com/sg/app/pockett-money-manager/id1555407900" sx={{ cursor: 'pointer' }}>
              <img src="images/app_store_button.svg" alt="Apple App Store" />
            </Link>
            <Ratings ratings={iosRatings} />
          </div>
          <div className={classes.storeInfoContainer}>
            <Link href="https://play.google.com/store/apps/details?id=app.pockett.pockett_app" sx={{ cursor: 'pointer' }}>
              <img src="images/google_play_button.svg" alt="Google Play Store" />
            </Link>
            <Ratings ratings={androidRatings} />
          </div>
        </div>
      </Section>
    </div>
  );
};

LandingPage.propTypes = {
  iosRatings: PropTypes.number,
  androidRatings: PropTypes.number,
  fetchIOSRatings: PropTypes.func,
  fetchAndroidRatings: PropTypes.func,
};

LandingPage.defaultProps = {
  iosRatings: 5,
  androidRatings: 5,
  fetchIOSRatings: () => {},
  fetchAndroidRatings: () => {},
};

export default LandingPage;