import { connect } from 'react-redux';

import { fetchIOSRatings, fetchAndroidRatings } from '../../ducks/AppStore/actions';
import LandingPage from './Home';

const mapStateToProps = (state) => {
  return {
    iosRatings: state.appStore.ratings.ios,
    androidRatings: state.appStore.ratings.android,
  };
};

const mapDispatchToProps = {
  fetchIOSRatings,
  fetchAndroidRatings,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);