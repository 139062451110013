import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  previewHolder: ({ width, height, fluid }) => ({
    cursor: 'pointer',
    width: fluid ? '100%' : `${width}px`,
    height: fluid ? '100%' : `${height}px`,
    background: '#ddd',
    marginRight: '1.5rem',
    overflow: 'hidden',
    transform: 'translateZ(0)',
    borderRadius: '8px',
  }),
  preview: ({ zoom, timing, fadeImage, opacity, width, height, fluid }) => ({
    width: fluid ? '100%' : `${width}px`,
    height: `${height}px`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: `transform ${timing}ms ease-out, opacity ${timing}ms, filter ${timing}ms`,
    filter: 'brightness(75%)',
    '&:hover':  {
      transform: `scale(${zoom})`,
      //opacity: fadeImage ? opacity : 1,
      filter: 'none',
    },
  }),
}));

const ImagePreview = (props) => {
  const classes = useStyles(props);

  const {
    image,
  } = props;

  return (
    <div className={classes.previewHolder}>
      <div className={classes.preview} style={{ backgroundImage: `url(${image})` }} />
    </div>
  );
};

ImagePreview.propTypes = {
  image: PropTypes.string.isRequired,
  zoom: PropTypes.number,
  timing: PropTypes.number,
  fadeImage: PropTypes.bool,
  opacity: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  fluid: PropTypes.bool,
};

ImagePreview.defaultProps = {
  zoom: 1.1,
  timing: 400,
  fadeImage: true,
  opacity: 0.7,
  width: 200,
  height: 150,
  fluid: false,
};

export default ImagePreview;