import axios from 'axios';
import {
  GET_IOS_APP_RATING,
  GET_IOS_APP_RATING_SUCCESS,
  GET_IOS_APP_RATING_FAILED,
  GET_ANDROID_APP_RATING,
  GET_ANDROID_APP_RATING_SUCCESS,
  GET_ANDROID_APP_RATING_FAILED,
} from './types';

export const fetchIOSRatings = (page = 1) => async dispatch => {
  dispatch({
    type: GET_IOS_APP_RATING,
  });
  return axios.get(`https://itunes.apple.com/search?term=Pockett&entity=software&country=SG`, {})
    .then((response) => {
      console.log('ios rating response', response.data);
      dispatch({
        type: GET_IOS_APP_RATING_SUCCESS,
        response: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: GET_IOS_APP_RATING_FAILED,
        errorMessage: error,
      });
      throw error;
    });
};

export const fetchAndroidRatings = (postId) => async dispatch => {
  dispatch({
    type: GET_ANDROID_APP_RATING,
  });
  return axios.get(`https://itunes.apple.com/search?term=Pockett&entity=software&country=SG`, {})
    .then((response) => {
      console.log('android rating response', response.data);
      dispatch({
        type: GET_ANDROID_APP_RATING_SUCCESS,
        post: response.data,
      });
      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: GET_ANDROID_APP_RATING_FAILED,
        errorMessage: error,
      });
      throw error;
    });
};

const actions = {
  fetchIOSRatings,
  fetchAndroidRatings,
};

export default actions;