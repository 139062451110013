import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import LandingPage from './components/LandingPage';
import Header from './components/Header';
import Footer from './components/Footer';
import PrivacyPage from './components/PrivacyPage';
import StoryPage from './components/StoryPage';
import ErrorPage from './components/ErrorPage';
import SupportPage from './components/SupportPage';
import useGlobalStyles from './GlobalStyles';
import FaqPage from './components/FaqPage/FaqPage';
import { BlogPage, PostDetailPage } from './components/BlogPage';
import TermsPage from './components/TermsPage';
import ScrollTop from './components/shared/ScrollTop/ScrollTop';

import './App.css';
import { Tooltip } from '@mui/material';
import DonationSuccessPage from './components/LandingPage/DonationSuccessPage';

function App(props) {
  const classes = useGlobalStyles();
  return (
    <Router>
      <CssBaseline />
      <div className={classes.root}>
        <Header iconClass={classes.icon} titleClass={classes.title} />
        <Toolbar id="top-anchor" />
        <main>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/ourstory" element={<StoryPage />} />
            <Route path="/contact" element={<SupportPage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/blog/:postId" element={<PostDetailPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/contribution/success" element={<DonationSuccessPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </main>
        <Footer />
        <ScrollTop {...props}>
          <Tooltip title="Back to top">
            <Fab color="secondary" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </Tooltip>
        </ScrollTop>
      </div>
    </Router>
  );
}

export default App;
