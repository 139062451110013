import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { socialMediaLinks } from '../../../constants/constants';

const useStyles = makeStyles((theme) => ({
  shareSocial: {
    display: 'flex',
  },
  socialIcon: {
    color: 'grey !important',
    transition: 'transform 200ms ease-out, color 200ms ease-out',
    '&:hover': {
      transform: 'scale(1.2) rotate(30deg)',
    },
  },
  twitter: {
    '&:hover': {
      color: '#00acee !important',
    }
  },
  facebook: {
    '&:hover': {
      color: '#3b5998 !important',
    }
  },
  email: {
    '&:hover': {
      color: `${theme.palette.secondary.main} !important`,
    }
  },
  link: {
    '&:hover': {
      color: '#ccc !important',
    }
  },
}));

const ShareIcons = (props) => {
  const classes = useStyles();
  const [ showCopyLink, setShowCopyLink ] = React.useState(false);

  const handleLink = (link) => {
    if (link !== '#') {
      return;
    }
    const input = document.body.appendChild(document.createElement("input"));
    input.value = window.location.href;
    input.focus();
    input.select();
    document.execCommand('copy');
    input.parentNode.removeChild(input);
    setShowCopyLink(true);
  };

  const getShareUrl = (replaceBase) => {
    if (replaceBase === '#') {
      return replaceBase;
    }
    return replaceBase.replace('#url', window.location.href);
  };

  const closeAlert = () => {
    setShowCopyLink(false);
  };

  return (
    <div>
      <Snackbar open={showCopyLink} onClose={closeAlert} autoHideDuration={2000}>
        <Alert>
          Link copied!
        </Alert>
      </Snackbar>
      <div className={classes.shareSocial}>
        { socialMediaLinks.map(social =>
          <Tooltip key={social.id} title={social.tooltip}>
            <IconButton
              className={clsx(classes.socialIcon, classes[social.className])}
              component={Link}
              href={getShareUrl(social.link)}
              target={social.link === '#' ? '_self' : '_blank'}
              rel="noopener"
              onClick={() => handleLink(social.link)}
              size="large">
              { React.createElement(social.icon) }
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ShareIcons;