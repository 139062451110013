import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const placeHolder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=';

const useStyles = makeStyles(() => ({
  '@keyframes loaded': {
    from: {
      opacity: 0.1,
    },
    to: {
      opacity: 1,
    },
  },
  image: {
    display: 'block',
    //width: '100px',
    //height: '100px',
    '&.loaded:not(.has-error)': {
      animation: `$loaded 3000ms ease-in-out`,
    },
    '&.has-error': {
      // fallback to placeholder image on error
      content: `url(${placeHolder})`,
    },
  },
}));

const LazyImage = (props) => {
  const {src, alt, ...other} = props;
  const [imageSrc, setImageSrc] = useState(null);
  const [imageRef, setImageRef] = useState();
  const classes = useStyles();

  const onLoad = event => {
    event.target.classList.add('loaded');
  };

  const onError = event => {
    event.target.classList.add('has-error');
  };

  useEffect(() => {
    let observer;
    let didCancel = false;

    if (imageRef && imageSrc !== src) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              // when image is visible in the viewport + rootMargin
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(src);
                observer.unobserve(imageRef);
              }
            })
          },
          {
            threshold: 0.01,
            rootMargin: '75%',
          }
        );
        observer.observe(imageRef);
      } else {
        // Old browsers fallback
        setImageSrc(src);
      }
    }
    return () => {
      didCancel = true;
      // on component unmount, we remove the listener
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef);
      }
    }
  }, [src, imageSrc, imageRef]);

  return (
    <img
      ref={setImageRef}
      className={classes.image}
      src={imageSrc}
      alt={alt}
      onLoad={onLoad}
      onError={onError}
      {...other}
    />
  );
};

export default LazyImage;