import {
  FETCH_POSTS,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILED,
  FETCH_POST, FETCH_POST_SUCCESS, FETCH_POST_FAILED
} from './types';

const initialState = {
  posts: [],
  totalPostCounts: 0,
  totalPages: 1,
  errorMessage: '',
  post: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POSTS:
      return {
        ...state,
      };
    case FETCH_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.posts,
        totalPages: action.totalPages,
        totalPostCounts: action.totalPostCounts,
      };
    case FETCH_POSTS_FAILED:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case FETCH_POST:
      return {
        ...state,
      };
    case FETCH_POST_SUCCESS:
      return {
        ...state,
        post: action.post,
      };
    case FETCH_POST_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default reducer;