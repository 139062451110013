import { connect } from 'react-redux';

import SupportPage from './SupportPage';
import { sendMessage } from '../../ducks/Feedback/actions';

const mapStateToProps = (state) => ({
  sendingMessage: state.feedback.sendingMessage,
  messageSent: state.feedback.messageSent,
  errorMessage: state.feedback.errorMessage,
});

const mapDispatchToProps = {
  sendMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportPage);