import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'block',
    position: 'relative',
    marginLeft: '0.3rem',
    marginRight: '0.3rem',
    overflow: 'hidden',
    textAlign: 'center',
    cursor: 'default',
  },
  featureTextBlock: {
    display: 'block',
    position: 'relative',
    color: theme.palette.primary.main,
  },
  visible: {
    visibility: 'visible',
    display: 'block',
  },
  invisible: {
    visibility: 'hidden',
    display: 'none',
  }
}));

const directions = ['up', 'down', 'left', 'right'];

const FeaturesText = (props) => {
  const classes = useStyles();
  const [timerId, setTimerId] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const [visible, setVisible] = useState(0);
  const containerRef = useRef(null);

  const {
    features,
    duration,
    loop,
    pauseDuration,
  } = props;

  useEffect(() => {
    playAnimation(visible);
  }, []);

  const playAnimation = (visible) => {
    const interval = duration / features.length;
    if (timerId !== null) {
      //setVisible((visible + 1) % features.length);
      clearTimeout(timerId);
    }

    if (visible + 1 >= features.length) {
      if (visible + 1 === features.length) {
        const tId = setTimeout(() => {
          //console.log('here', visible);
          setShowAll(true);
          playAnimation((visible + 1));
        }, interval);
        setTimerId(tId);
      } else {
        // reset to start
        if (loop) {
          const tId = setTimeout(() => {
            //console.log('here', visible);
            setShowAll(false);
            setVisible(0);
            playAnimation(0);
          }, interval + pauseDuration);
          setTimerId(tId);
        }
      }
    } else {
      const tId = setTimeout(() => {
        setVisible((visible + 1) % features.length);
        playAnimation((visible + 1) % features.length);
      }, interval);
      setTimerId(tId);
    }
  };

	return (
    <div className={classes.wrapper} ref={containerRef}>
      {showAll ? (
        <Grow in={true}>
          <div className={clsx(classes.featureTextBlock, classes.visible)}>
            {features.map((feature, i) => feature).join(' / ')}
          </div>
        </Grow>
      ) : features.map((feature, i) => (
        <Slide key={`slide-${i}`} in={visible === i} direction={directions[i % directions.length]} ref={containerRef.current}>
          <div className={clsx(classes.featureTextBlock, visible === i ? classes.visible : classes.invisible)} key={i}>
            {feature}
          </div>
        </Slide>
      ))}
    </div>
  );
};

FeaturesText.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  duration: PropTypes.number,
  pauseDuration: PropTypes.number,
  loop: PropTypes.bool,
};

FeaturesText.defaultProps = {
  duration: 5000,
  pauseDuration: 3000,
  loop: true,
};

export default FeaturesText;