import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import makeStyles from '@mui/styles/makeStyles';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {
  CircularProgress,
  FormControl, FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar
} from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';

import StyledButton from '../shared/Button/Button';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';


const useStyles = makeStyles((theme) => ({
  actionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: '1rem',
  },
  disclaimer: {
    marginTop: '1rem',
    color: '#ccc',
    fontSize: '0.7rem',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    background: theme.palette.background.paper,
    padding: '2rem',
    outline: 'none',
    borderRadius: '4px',
    boxShadow: '#333 0 3px 12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  loadingIcon: {
    animation: '$spin 1s infinite linear',
    marginRight: '1rem',
  },
  contactForm: {
    background: `linear-gradient(to bottom, ${theme.palette.primary.main}, #755fe2)`,//theme.palette.background.paper,
    marginBottom: '3rem',
    padding: '1.6rem',
    paddingBottom: 0,
    '@media screen and (max-width: 600px)': {
      padding: '0.2rem',
      paddingBottom: 0,
    }
  },
}));

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const SupportPage = (props) => {
  const classes = useStyles();
  const [ message, setMessage ] = React.useState('');
  const [ email, setEmail ] = React.useState('');
  const [ name, setName ] = React.useState('');
  const [ issueType, setIssueType ] = React.useState('');
  const [ showToast, setShowToast ] = React.useState(false);
  const [ submitted, setSubmitted ] = React.useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const sendMessage = async () => {
    setSubmitted(true);
    if (!executeRecaptcha) {
      return;
    }
    const result = await executeRecaptcha('sendMessage');
    if (validateEmail(email) && message.length > 0 && validateIssueType(issueType)) {
      props.sendMessage(message, name, email, issueType, result);
    }
  };

  const validateEmail = (str) => {
    if (str !== undefined && str.length > 0 && emailRegex.test(str)) {
      return true;
    }
    return false;
  };

  const validateName = (str) => {
    if (str !== undefined && str.length > 0) {
      return true;
    }
    return false;
  };

  const getValidationMessage = (field) => {
    if (field === 'email') {
      if (email.length === 0) {
        return 'Email cannot be empty';
      } else if (!emailRegex.test(email)) {
        return 'Invalid email address';
      }
    } else if (field === 'name') {
      return 'Please enter a name';
    }
    return '';
  };

  const validateIssueType = (str) => {
    const validValues = ['feedback', 'issue'];
    if (str !== undefined && str.length > 0 && validValues.includes(str)) {
      return true;
    }
    return false;
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleIssueTypeChange = (event) => {
    setIssueType(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleToastClose = (event) => {
    setShowToast(false);
  };

  const {
    sendingMessage,
    messageSent,
    errorMessage,
  } = props;

  useEffect(() => {
    const toastStatus = !sendingMessage && (messageSent || (!messageSent && errorMessage.length !== 0));
    setShowToast(toastStatus);
  }, [sendingMessage, messageSent, errorMessage]);

  return (
    <div className="content-page">
      <Modal
        open={sendingMessage}
        closeAfterTransition
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={sendingMessage} unmountOnExit>
          <div className={classes.modalContent}>
            <CircularProgress style={{ marginRight: '1rem' }} />
            <Typography variant="subtitle1">Sending message...</Typography>
          </div>
        </Fade>
      </Modal>
      { showToast &&
        messageSent ? <Snackbar open={showToast} autoHideDuration={6000} onClose={handleToastClose}>
          <Alert severity="success" onClose={handleToastClose}>
            <AlertTitle>Success</AlertTitle>
            Message is sent!
          </Alert>
        </Snackbar> :
        <Snackbar open={showToast} autoHideDuration={6000} onClose={handleToastClose}>
          <Alert severity="error" onClose={handleToastClose}>
            Unable to send message - {errorMessage}
          </Alert>
        </Snackbar>
      }
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid item md={5} xs={12} sx={{ flexDirection: 'column' }}>
            <Typography variant="body1" paragraph>Contact us</Typography>
            <Typography variant="h6" color="textPrimary">Feedback or questions?</Typography>
            <Typography variant="h6" color="textPrimary">We're here.</Typography>
          </Grid>
          <Grid item md={7} xs={12}>
            <Card className={classes.contactForm}>
              <CardContent>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="stretch"
                >
                  <Grid item>
                    <FormControl variant="filled" fullWidth error={submitted && !validateIssueType(issueType)}>
                      <InputLabel>What is it regarding?</InputLabel>
                      <Select
                        color="primary"
                        placeholder="Select issue type"
                        value={issueType}
                        onChange={handleIssueTypeChange}
                      >
                        <MenuItem value="feedback">Feedback</MenuItem>
                        <MenuItem value="issue">Technical Issue</MenuItem>
                      </Select>
                      { submitted && !validateIssueType(issueType) && <FormHelperText>Please select an issue</FormHelperText> }
                    </FormControl>

                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      autoFocus
                      variant="filled"
                      color="primary"
                      placeholder="Your name"
                      label="Your name"
                      type="text"
                      value={name}
                      error={submitted && !validateName(name)}
                      helperText={submitted && !validateName(name) ? getValidationMessage('name') : null}
                      onChange={handleNameChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      autoFocus
                      variant="filled"
                      color="primary"
                      placeholder="Your email"
                      label="Your email"
                      type="email"
                      value={email}
                      error={submitted && !validateEmail(email)}
                      helperText={submitted && !validateEmail(email) ? getValidationMessage('email') : null}
                      onChange={handleEmailChange}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      multiline
                      rows={8}
                      variant="filled"
                      color="primary"
                      placeholder="Send a message"
                      label="Message"
                      error={submitted && message.length === 0}
                      helperText={submitted && message.length === 0 ? 'Message cannot be empty' : null}
                      className={classes.contactMultiline}
                      onChange={handleMessageChange}
                    />
                  </Grid>
                  <Grid item>
                    <div className={classes.actionContainer}>
                      <StyledButton onClick={sendMessage}>Send message</StyledButton>
                      <Typography variant="subtitle2" component="small" className={classes.disclaimer}>
                        This site is protected by reCAPTCHA and the Google
                        <Link href="https://policies.google.com/privacy"> Privacy Policy</Link> and
                        <Link href="https://policies.google.com/terms"> Terms of Service</Link> apply.
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

SupportPage.propTypes = {
  sendMessage: PropTypes.func,
  messageSent: PropTypes.bool,
  sendingMessage: PropTypes.bool,
  errorMessage: PropTypes.string,
};

SupportPage.defaultProps = {
  sendMessage: () => {},
  messageSent: false,
  sendingMessage: false,
  errorMessage: '',
};

export default SupportPage;