import axios from 'axios';
import { SENDING_MESSAGE, MESSAGE_SENT_FAILED, MESSAGE_SENT } from './types';

export const sendMessage = (message, name, email, issueType, token) => async dispatch => {
    dispatch({
      type: SENDING_MESSAGE,
    });
    return axios.post(`${process.env.REACT_APP_API_BASE}/api/messages/feedback`, {
      request: {
        senderName: name,
        senderEmail: email,
        type: issueType,
        message,
      },
      token,
    }).then((response) => {
      dispatch({
        type: MESSAGE_SENT,
      });
    }).catch((error) => {
      dispatch({
        type: MESSAGE_SENT_FAILED,
        errorMessage: error,
      });
    });
};

export const dismissAlert = () => async dispatch => {
  dispatch({
    type: SENDING_MESSAGE,
  });
};

const actions = {
  sendMessage,
  dismissAlert,
};

export default actions;