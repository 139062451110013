import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          '&:hover': {
            backgroundColor: 'white',
          },
          '& .MuiSelect-filled': {
            color: '#1f2e35',
          },
          '& > input,textarea': {
            color: '#1f2e35',
          },
          '&.Mui-focused': {
            backgroundColor: 'white',
            '& > input,textarea': {
              color: '#1f2e35',
            }
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'rgba(255, 255, 255, 0.6)',
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          '& > a': {
            color: 'rgba(255,255,255,0.9)',
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root': {
            color: 'rgba(255,255,255,0.7)',
          }
        }
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          '& .MuiTypography-root': {
            color: 'rgba(255,255,255,0.9)',
          }
        },
        expandIconWrapper: {
          color: 'rgba(255,255,255,0.9)',
        }
      }
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'SF Pro Display',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '2.6rem',
      fontWeight: 'bold',
      fontFamily: 'SFProDisplay-Bold',
      color: '#1f2e35',
    },
    h2: {
      fontSize: '2.2rem',
      fontWeight: 600,
      fontFamily: 'SFProDisplay-Bold',
      color: '#1f2e35',
    },
    h3: {
      fontSize: '2.0rem',
      fontWeight: 600,
      fontFamily: 'SFProDisplay-Bold',
      color: '#1f2e35',
    },
    h4: {
      fontSize: '1.8rem',
      fontWeight: 400,
      fontFamily: 'SFProDisplay-Bold',
      color: '#1f2e35',
    },
    h5: {
      fontSize: '1.6rem',
      fontFamily: 'SFProDisplay-Bold',
      color: '#1f2e35',
    },
    h6: {
      fontSize: '1.4rem',
      fontWeight: 600,
      fontFamily: 'SFProDisplay-Bold',
      color: '#1f2e35',
    },
    body1: {
      fontWeight: 500,
      fontFamily: 'SF Pro Display',
      fontSize: '18px',
      //color: '#5d747f',
      color: '#96a7af',
    },
    body2: {
      fontFamily: 'SF Pro Display',
      fontSize: '16px',
    },
    button: {
      textTransform: 'none',
      fontFamily: 'SF Pro Display',
    },
    a: {
      textDecoration: 'none',
      fontFamily: 'SF Pro Display',
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#37c3c8',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffc542',
    },
    error: {
      main: '#ff575f',
    },
    warning: {
      main: '#ffc542',
    },
    background: {
      default: '#1f2e35',
      paper: '#30444e',
    },
    text: {
      primary: 'rgba(255,255,255,0.9)',
      secondary: 'rgba(255,255,255,0.7)',
    },
    divider: 'rgba(150, 150, 150, 0.3)',
  },
});

export default theme;