import PropTypes from 'prop-types';

export const AuthorType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  avatar_urls: PropTypes.shape({
    '24': PropTypes.string,
    '48': PropTypes.string,
    '96': PropTypes.string,
  }),
  meta: PropTypes.array,
  _links: PropTypes.shape({
    self: PropTypes.arrayOf(PropTypes.shape({
      href: PropTypes.string,
    })),
    collection: PropTypes.arrayOf(PropTypes.shape({
      href: PropTypes.string,
    })),
  }),
});