import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Avatar, CardContent, CardMedia } from '@mui/material';
import Container from '@mui/material/Container';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import clsx from 'clsx';
import moment from 'moment';

import useGlobalStyles from '../../GlobalStyles';
import { PostType } from '../../models/PostType';
import ShareIcons from '../shared/ShareIcons/ShareIcons';

const useStyles = makeStyles((theme) => ({
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    '& h3': {
      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
      }
    },
  },
  backButton: {
    marginRight: '2rem',
    [theme.breakpoints.down('md')]: {
      marginRight: '0.5rem',
    },
  },
  card: {
    marginBottom: '3rem',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    '& p': {
      lineHeight: 1.75,
    },
  },
  cardContent: {
    paddingLeft: '3rem',
    paddingRight: '3rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    '& .wp-block-table': {
      [theme.breakpoints.down('md')]: {
        marginInlineStart: '10px',
        marginInlineEnd: '10px',
      },
    },
    '& .wp-block-image': {
      textAlign: 'center',
      margin: '0 0 1em',
      [theme.breakpoints.down('md')]: {
        marginInlineStart: 0,
        marginInlineEnd: 0,
      },
    },
    '& img': {
      maxWidth: '100%',
      //height: '100%',
      //objectFit: 'contain',
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    }
  },
  floatingCardMedia: {
    width: '100%',
    height: '300px',
    transition: 'background 400ms ease-out',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
    '&:hover': {
      backgroundSize: '120%',
    },
  },
  authorInfo: {
    marginTop: theme.spacing(5),
    display: 'flex',
    alignItems: 'center',
  },
  avatarLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
  fadeOffContainer: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  fadeOff: {
    position: 'absolute',
    width: '100%',
    top: 0,
    bottom: 0,
    background: `linear-gradient(180deg, ${alpha(theme.palette.background.paper, 0)} 55%, ${theme.palette.background.paper} 85%)`,
  },
}));

const PostDetailPage = (props) => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const { postId } = useParams();
  const navigate = useNavigate();

  const {
    post,
    fetchPost,
  } = props;

  useEffect(() => {
    if (post === null) {
      fetchPost(postId).then(() => {
        fetchComments();
      });
    } else {
      fetchComments();
    }
  });

  const __semio__onload = () => {
    window.__semio__gc_graphlogin({
      graphcommentId: process.env.REACT_APP_GRAPHCOMMENT_ID,
      behaviour: {
        uid: postId,
      },
    });
  };

  const fetchComments = () => {
    const gc = document.createElement('script');
    gc.type = 'text/javascript';
    gc.async = true;
    gc.onload = __semio__onload;
    gc.defer = true;
    gc.src = 'https://integration.graphcomment.com/gc_graphlogin.js?' + Date.now();
    const comments = document.getElementById('graphcomment');
    if (comments) {
      comments.appendChild(gc);
    }

    return () => {
      const comments = document.getElementById('graphcomment');
      if (comments)
        comments.innerHTML = '';
      const iframes = document.getElementsByTagName('iframe');
      console.log('iframes', iframes);
    };
  };

  const featuredMedias = post === null ? {} : post._embedded['wp:featuredmedia'];

  return post === null ? <div className="content-page">
    Loading...
  </div> :
  <div className="content-page">
    <Container maxWidth="md">
      <div className={clsx(globalStyles.pageTitle, classes.titleRow)}>
        <Tooltip title="Back to blog">
          <IconButton
            className={classes.backButton}
            onClick={() => navigate(-1)}
            size="large">
            <ArrowBackIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Typography variant="h3" align="center" color="textPrimary">{post.title.rendered}</Typography>
      </div>
      <span itemProp="image" itemScope itemType="image/jpeg">
        <link itemProp="url" href={ featuredMedias ? featuredMedias[0].source_url : '/images/penpaper_splash.jpg' } />
      </span>
      <Card className={classes.card}>
        <div className={classes.fadeOffContainer}>
        { featuredMedias ?
          <CardMedia className={classes.floatingCardMedia} image={featuredMedias[0].source_url} /> :
          <CardMedia component="img" className={classes.floatingCardMedia} image="/images/penpaper_splash.jpg" />
        }
          <div className={classes.fadeOff} />
        </div>
        <CardContent className={classes.cardContent}>
          <Typography color="textSecondary" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
          <div className={classes.authorInfo}>
            <Avatar
              className={classes.avatarLarge}
              alt={post._embedded.author[0].name}
              src={post._embedded.author[0].avatar_urls['96']}
            />
            <Typography>By { post._embedded.author[0].name }, { moment(post.date).fromNow() }</Typography>
          </div>
          <ShareIcons />

          <div id="graphcomment"/>
        </CardContent>
      </Card>
    </Container>
  </div>;
};

PostDetailPage.propTypes = {
  post: PostType,
  fetchPost: PropTypes.func,
};

export default PostDetailPage;