import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Card, CardContent } from '@mui/material';
import useGlobalStyles from '../../GlobalStyles';
import makeStyles from '@mui/styles/makeStyles';
import Link from '@mui/material/Link';

const useStyles = makeStyles((theme) => ({
  termsCard: {
    padding: '3.5rem',
    marginBottom: '3rem',
    [theme.breakpoints.down('md')]: {
      padding: '1rem',
    },
    '& p': {
      marginBottom: '2rem',
    },
    '& li': {
      marginTop: '1rem',
    },
  },
}));

const TermsPage = (props) => {
  const globalStyles = useGlobalStyles();
  const classes = useStyles();

  return (
    <div className="content-page">
      <Typography variant="h3" align="center" className={globalStyles.pageTitle} color="textPrimary">Terms of Use</Typography>
      <Container maxWidth="md">
        <Card className={classes.termsCard}>
          <CardContent>
            <Typography variant="h6" color="textPrimary" gutterBottom>General</Typography>
            <Typography paragraph>
              These Terms of Use (the “Terms”) govern your use of the Pockett Pte. Ltd. (“Pockett”) service available
              through our software application entitled “Pockett” (the “App”) that enables users to track their finances
              on their mobile device or access account-related functions (the “Service”).
            </Typography>
            <Typography paragraph>
              Please read these Terms and our <Link href="/privacy">Privacy Policy</Link> which addresses how we handle and protect your data and
              information. By accessing, browsing or using the Service, and/or downloading the App, you represent that
              (1) you are of legal age to form a binding contract with Pockett, (2) you have the authority to enter into
              the Terms personally or on behalf of the company you represent, and (3) agree to be legally bound by the
              Terms and our Privacy Policy as if such agreement was executed in writing and by your own hand. If you do
              not agree to be bound by these Terms, you may not access or use the App or Service.
            </Typography>
            <Typography paragraph>
              All updates and upgrades to the App will be governed by the version of these Terms published by Pockett as
              of the date you install such update or upgrade.
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Changes</Typography>
            <Typography paragraph>
              We may revise these Terms from time to time and publish an updated version on our Website. The revised
              Terms will apply to the use of our App and Service from the date of the publication of the revised Terms
              at the bottom of this page. Your continued use of our Services will be subjected to the new terms.
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Acceptable Use</Typography>
            <Typography paragraph>
              Pockett grants you a limited, non-exclusive, non-transferrable, non-sublicenseable license to download, view
              and display pages from the App and/or Service for your own personal non-commercial purposes, subject to the restrictions below.
            </Typography>
            <Typography component={'div'} paragraph>
              Without limiting any other provision in these Terms, you agree not to, and will not assist, encourage, or
              enable others to use the App and/or Service to:
              <ol type="a">
                <li>sell, rent or otherwise sub-license material from the App;</li>
                <li>reproduce, duplicate, copy or otherwise exploit material on the App for any commercial purpose;</li>
                <li>access, monitor or copy any content or information of the App using any robot, spider, scraper or
                  other automated means or any manual process for any purpose;</li>
                <li>take any action that imposes, or may impose, in our discretion, an unreasonable load on our infrastructure;</li>
                <li>use any device, software or routine that interferes with the proper working of the App, or otherwise
                  attempt to interfere with the proper working of the App;</li>
                <li>reproduce, redistribute, modify, translate, adapt, merge, make derivative works of, disassemble,
                  decompile, reverse engineer, or disassemble any part of the App;</li>
                <li>make the functionality of the App available to multiple users through any means;</li>
                <li>frame, mirror, reformat, or otherwise incorporate any part of the App into any other website or app
                  without our prior written authorization; or</li>
                <li>access the App in order to build a similar or competitive website, application or service.</li>
              </ol>
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Additional Terms for App Store Apps</Typography>
            <Typography paragraph>
              If you accessed or downloaded the App from the Apple Store, then you agree to use the App only:
              <ol type="a">
                <li>on an Apple-branded product or device that runs iOS (Apple's proprietary operating system software); and</li>
                <li>as permitted by the "Usage Rules" set forth in the Apple Store Terms of Service.</li>
              </ol>
            </Typography>
            <Typography paragraph>
              If you accessed or downloaded the App from an App Provider, then you acknowledge and agree that:
              <ul>
                <li>These Terms are concluded between you and Pockett, and not with App Provider, and that, as between Pockett and the App Provider, Pockett, is solely responsible for the App.</li>
                <li>App Provider has no obligation to furnish any maintenance and support services with respect to the App.</li>
                <li>In the event of any failure of the App to conform to any applicable warranty, you may notify App Provider and 
                App Provider will refund the purchase price for the App to you (if applicable) and, to the maximum extent permitted by applicable law, App Provider will have no other warranty obligation whatsoever with respect to the App. Any other claims, losses, liabilities, damages, costs or expenses attributable to any failure of an App to conform to any warranty will be the sole responsibility of Pockett.</li>
                <li>App Provider is not responsible for addressing any claims you have or any claims of any third party relating to 
                the App or your possession and use of the App, including, but not limited to: (i) product liability claims; (ii) any 
                claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising 
                under consumer protection or similar legislation.</li>
                <li>In the event of any third-party claim that the App or your possession and use of the App infringes that third 
                party’s intellectual property rights, Pockett will be solely responsible for the investigation, defense, settlement 
                and discharge of any such intellectual property infringement claim to the extent required by these Terms.</li>
                <li>App Provider and its subsidiaries are third-party beneficiaries of these Terms as related to your license of 
                the App, and that, upon your acceptance of the terms and conditions of these Terms, App Provider will have the 
                right (and will be deemed to have accepted the right) to enforce these Terms as related to your license of the 
                App against you as a third party beneficiary thereof.</li>
                <li>You must also comply with all applicable third-party terms of service when using the App.</li>
                <li>By using the App you represent and warrant that: (i) you are not located in a country that is subject to a U.S. 
                Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and 
                (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</li>
              </ul>
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Copyright</Typography>
            <Typography paragraph>
              Any design, layout, logo, text, code, and graphics contained within the App is, and shall remain the sole
              property of Pockett and is subjected to protection under Singapore and international treaties, trademark,
              patent laws and/or all other intellectual property laws, as may be applicable
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Modifying and Terminating our Service</Typography>
            <Typography paragraph>
              We are constantly modifying and improving our Service over time. We may add or remove features or
              functionalities, and we may temporarily suspend or discontinue the Service altogether. Should we change or
              impose fees for your access and use of the Service in the future, we will provide at least 14 days’ notice to you.
            </Typography>
            <Typography paragraph>
              You can stop using the Service at any time. Pockett may also stop providing the Service to you at any time,
              with or without cause, and with or without notice. For example, we may immediately suspend or terminate
              your use if you are not complying with these Terms, or use the Service in any way that would cause us legal
              liability or disrupt others’ use of the Service.
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Future Functionality</Typography>
            <Typography paragraph>
              You agree that your purchases are not contingent on the delivery of any future functionality or features, or 
              dependent on any oral or written public comments made by Pockett regarding future functionality or features.
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Disclaimer of Warranty</Typography>
            <Typography paragraph>
              The App and Service are provided “as is” without warranties, conditions, representations or guaranties of
              any kind, either expressed, implied, statutory or otherwise, including but not limited to, any implied
              warranties or conditions of merchantability, satisfactory quality, fitness for a particular purpose and
              non-infringement, with respect to the use of this App and its Services, or the accuracy, completeness,
              timeliness or currentness of its content, in any way and for any purpose. Pockett does not warrant the
              operation of its App and Service will be uninterrupted or error free.
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Jurisdiction and Applicable Law</Typography>
            <Typography paragraph>
              These Terms will be governed by and construed in accordance with the laws of Singapore.
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Arbitration Agreement</Typography>
            <Typography paragraph>
              This Agreement shall be governed and construed in accordance with the laws of Singapore. Any dispute
              arising out of or in connection with this Agreement, including any question regarding its existence,
              validity or termination, shall be referred to and resolved by arbitration in Singapore in accordance with
              the Arbitration Rules of Singapore International Arbitration Centre (“SIAC”) for the time being in force
              which rules are deemed to be incorporated by reference to this clause. The tribunal shall consist of one
              (1) arbitrator to be appointed by the President of the SIAC Court of Arbitration. The language of the
              arbitration shall be in English. The physical venue of the arbitration shall be Singapore. The decision of
              the arbitrator shall be final and binding on the Parties and may be used (without limitation) as a basis
              for judgment in any country which has ratified the 1958 New York Convention on the Recognition and
              Enforcement of Foreign Arbitral Awards.
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Limitation of Liability</Typography>
            <Typography paragraph>
              To the maximum extent permitted by law, Pockett will not be liable to you for any loss (including loss of
              information, data, revenues, profits or savings) or damage resulting, directly or indirectly, from any use
              of, or reliance on the Service. You assume sole responsibility for results obtained from the use of the
              Service and the conclusions drawn from such use.
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Indemnification</Typography>
            <Typography paragraph>
              You agree to indemnify and hold harmless Pockett and their respective directors, officers, employees, and
              agents from and against any and all claims and expenses, including attorneys’ fees, arising out of your
              use of the Service, including but not limited to your violation of these Terms.
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Entire Agreement</Typography>
            <Typography paragraph>
              These Terms, together with our <Link href="/privacy">Privacy Policy</Link> constitutes the entire agreement between you and us in
              relation to your use of the App and Service and supersedes all previous agreements in respect of your use
              of our App and Service.
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Unenforceable Provisions</Typography>
            <Typography paragraph>
              If any provision of this disclaimer is, or is found to be, unenforceable under applicable law, that will
              not affect the enforceability of the other provisions of this disclaimer.

              If you have any questions regarding our Terms, please contact us at <Link href="mailto:support@pockett.app">support@pockett.app</Link>.
            </Typography>
            <Typography gutterBottom>Last Updated: Feb 06, 2022</Typography>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default TermsPage;