import PropTypes from 'prop-types';

export const PostType = PropTypes.shape({
  id: PropTypes.number,
  author: PropTypes.number,
  categories: PropTypes.arrayOf(PropTypes.number),
  date: PropTypes.string,
  link: PropTypes.string,
  excerpt: PropTypes.shape({
    protected: PropTypes.bool,
    rendered: PropTypes.string,
  }),
  content: PropTypes.shape({
    protected: PropTypes.bool,
    rendered: PropTypes.string,
  }),
  title: PropTypes.shape({
    rendered: PropTypes.string,
  }),
  comment_status: PropTypes.string,
  featured_media: PropTypes.number,
  type: PropTypes.string,
  _links: PropTypes.shape({
    author: PropTypes.arrayOf(PropTypes.shape({
      embeddable: PropTypes.bool,
      href: PropTypes.string,
    })),
    replies: PropTypes.arrayOf(PropTypes.shape({
      embeddable: PropTypes.bool,
      href: PropTypes.string,
    })),
  }),
});