import {
  GET_IOS_APP_RATING,
  GET_IOS_APP_RATING_SUCCESS,
  GET_ANDROID_APP_RATING,
  GET_ANDROID_APP_RATING_SUCCESS,
} from './types';

const initialState = {
  ratings: {
    ios: 5,
    android: 4.5,
  }
};

const findRelevantResult = (response) => response.results.find((r) => r.artistId === 1555075873);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IOS_APP_RATING:
      return state;
    case GET_IOS_APP_RATING_SUCCESS:
      let result = findRelevantResult(action.response);
      return {
        ...state,
        ratings: {
          ...state.ratings,
          ios: result.averageUserRating,
        }
      };
    case GET_ANDROID_APP_RATING:
      return state;
    case GET_ANDROID_APP_RATING_SUCCESS:
      let androidResult = findRelevantResult(action.response);
      return {
        ...state,
        ratings: {
          ...state.ratings,
          android: androidResult.averageUserRating,
        }
      };
    default:
      return state;
  }
};

export default reducer;