import { connect } from 'react-redux';

import BlogPage from './BlogPage';
import PostDetailPage from './PostDetailPage';
import { fetchPosts, fetchPost, } from '../../ducks/Blog/actions';

const mapStateToProps = (state) => ({
  posts: state.blog.posts,
  totalPages: state.blog.totalPages,
  totalPostCounts: state.blog.totalPostCounts,
  errorMessage: state.blog.errorMessage,
  authors: state.blog.authors,
});

const mapDispatchToProps = {
  fetchPosts,
  fetchPost,
};

const BlogPageContainer = connect(mapStateToProps, mapDispatchToProps)(BlogPage);


const mapStateToPostProps = (state) => ({
  post: state.blog.post,
});

const mapDispatchToPostProps = {
  fetchPost,
};

const PostDetailPageContainer = connect(mapStateToPostProps, mapDispatchToPostProps)(PostDetailPage);

export {
  BlogPageContainer as BlogPage,
  PostDetailPageContainer as PostDetailPage,
};