import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { lighten } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

const StyledButton = withStyles((theme) => ({
  primary: {
    background: 'linear-gradient(180deg, #2cced5 30%, #1fa3aa 90%)',
    borderRadius: 5,
    border: 0,
    color: 'white',
    fontWeight: 800,
    fontSize: '0.9rem',
    fontFamily: 'Montserrat',
    letterSpacing: '0.06rem',
    padding: '10px 30px',
    boxShadow: '0 2px 3px 1px rgba(70,70,70, 0.3)',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    '&.Mui-disabled': {
      background: `linear-gradient(180deg, ${lighten('#2cced5', 0.4)} 30%, ${lighten('#1fa3aa', 0.4)} 90%)`,
    },
    '&:hover': {
      background: `linear-gradient(180deg, ${lighten('#2cced5', 0.3)} 30%, ${lighten('#1fa3aa', 0.1)} 90%)`,
    },
  },
  secondary: {
    background: 'white',
    padding: '10px 30px',
  },
}))(({ classes, mode, to, type, disabled, ...other }) => (
  <Button
    variant="contained"
    component={to !== null ? Link : Button}
    className={classes[mode]}
    to={to}
    type={type}
    disabled={disabled}
    {...other}
  />
));

StyledButton.propTypes = {
  mode: PropTypes.oneOf(['primary', 'secondary']),
  to: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

StyledButton.defaultProps = {
  mode: 'primary',
  to: null,
  type: 'button',
  disabled: false,
};

export default StyledButton;