import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import Link from '@mui/material/Link';

const pageStyle = makeStyles((theme) => ({
  paragraph: {
    position: 'relative',
    marginBottom: '2.5rem',
  },
  jumboContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '450px',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.main,
    '@media screen and (max-width: 600px)': {
      height: '200px',
    }
  },
  hello: {
    fontSize: '5rem',
    fontStyle: 'italic',
    fontFamily: 'Lora',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.2rem',
    }
  },
  helloCaption: {
    fontSize: '1.05rem',
    fontFamily: 'Lora',
  },
  redDot: {
    display: 'inline-block',
    marginLeft: '5px',
    width: '15px',
    height: '15px',
    borderRadius: '10px',
    backgroundColor: theme.palette.error.main,
  },
  characterContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    '@media screen and (max-width: 600px)': {
      height: '190px',
    }
  }
}));

const StoryPage = () => {
  const classes = pageStyle();
  return (
    <div>
      <div className={classes.jumboContainer}>
        <Container
          maxWidth="lg"
          align="center"
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
        >
          <Grid container>
            <Grid item xs={2} sm={3} className={classes.characterContainer}>
              <img height="80%" src="images/female.svg" alt="female" />
            </Grid>
            <Grid item xs={8} sm={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h1" className={classes.hello}>
                Hello
                <span className={classes.redDot} />
              </Typography>
              <Typography variant="h6" className={classes.helloCaption}>
                We are A & B.
              </Typography>
            </Grid>
            <Grid item xs={2} sm={3} className={classes.characterContainer}>
              <img height="80%" src="images/male.svg" alt="male" />
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{ marginTop: '2rem' }}></div>
      <Container maxWidth="sm" fixed className={classes.paragraph}>
        <Typography>Inherently shy and incredibly private, we go by our initials on the web. We met on the first day of university
        over 13 years ago, and have gone on adventures, faced challenges, and created incredible memories together ever since.</Typography>
      </Container>
      <Container maxWidth="sm" fixed className={classes.paragraph}>
        <Typography variant="h6" gutterBottom color="textPrimary">Hobby turned passion</Typography>
        <Typography>
          What started out as a passion turned into an obsession and shared excitement. We are both emotionally invested in building
          a personal finance app that empowers our family, friends and people around the world to make money decisions with confidence.
          We are professionals in the IT and MedTech industry by day, and work tirelessly in our spare time to bring Pockett to life.
        </Typography>
      </Container>
      <Container maxWidth="sm" fixed className={classes.paragraph}>
        <Typography variant="h6" gutterBottom color="textPrimary">Two heads, many hats</Typography>
        <Typography>
          The development and running of Pockett are currently funded by our savings. So we do everything ourselves
          to keep costs low. We are Pockett's software developers, UX/UI designers, web designers, copywriters, app testers, funders,
          and so much more.
        </Typography>
      </Container>
      <Container maxWidth="sm" fixed className={classes.paragraph}>
        <Typography variant="h6" gutterBottom color="textPrimary">Incredible learning journey</Typography>
        <Typography>
          We've had so much fun creating Pockett and are super excited about what's still to come. We are determined to bring you a
          personal finance app that grows with you. If you have feedback, questions, or just want to say hi, drop us a note at &nbsp;
          <Link href="mailto:support@pockett.app">support@pockett.app</Link> — we'd love to hear from you!
        </Typography>
      </Container>
    </div>
  );
};

export default StoryPage;