import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Card, CardContent, Fade, useMediaQuery, useTheme, Zoom } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

import useGlobalStyles from '../../GlobalStyles';
import { PostType } from '../../models/PostType';
import { AuthorType } from '../../models/AuthorType';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router';
import ImagePreview from '../shared/ImagePreview/ImagePreview';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Pagination from '@mui/material/Pagination';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginBottom: theme.spacing(2),
  },
  pagination: {
    marginBottom: '3rem',
    '& ul': {
      justifyContent: 'flex-end',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem',
  },
  post: {
    display: 'flex',
    paddingBottom: '1rem',
    '& + &': {
      borderTop: 'solid 1px rgba(200, 200, 200, 0.1)',
      paddingTop: '1.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  preview: {
    width: '200px',
    marginRight: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  cardContent: {
    width: 'calc(100% - 200px)',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
  },
  featuredMedia: {
    cursor: 'pointer',
  },
  postMetadata: {
    color: '#aaa',
  },
  postTitle: {
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      transition: 'color 300ms',
      '&:hover': {
        color: theme.palette.primary.main,
      }
    },
  },
  postLink: {
    cursor: 'pointer',
  },
  postContent: {},
  author: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  excerpt: {
    display: '-webkit-box',
    overflow: 'hidden',
    maxHeight: '4.2rem',
    boxOrient: 'vertical',
    wordWrap: 'break-word',
    textOverflow: 'ellipsis',
    lineClamp: 2,
  },
}));

const Post = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const [isHoverReadMore, setHoverReadMore] = React.useState(false);

  const { post, fetchPost } = props;

  const featuredMedias = post._embedded['wp:featuredmedia'];

  const hoverReadMore = () => {
    setHoverReadMore(true);
  };

  const leaveReadMore = () => {
    setHoverReadMore(false);
  };

  const showPost = (postId) => {
    fetchPost(postId).then((response) => {
      navigate(`/blog/${postId}`);
    });
  };

  return (
    <div className={classes.post}>
      <div className={classes.preview} onClick={() => showPost(post.id)}>
        { featuredMedias !== undefined ?
          <ImagePreview
            image={featuredMedias[0].source_url}
            fluid={!isDesktop}
          /> :
          <ImagePreview image="images/penpaper_splash.jpg" fluid={!isDesktop} />
        }
      </div>
      <div className={classes.cardContent}>
        <div className={classes.postMetadata}>
          <Typography gutterBottom variant="subtitle2">{post._embedded['wp:term'][0][0].name} / { moment(post.date).startOf('hour').fromNow() }</Typography>
        </div>
        <div className={classes.postTitle}>
          <Typography variant="h3" color="textPrimary" className={classes.postLink} onClick={() => showPost(post.id)}>
            {post.title.rendered}
          </Typography>
        </div>
        <div className={classes.postContent}>
          <Typography
            className={classes.excerpt}
            color="textSecondary"
            variant="body1"
            paragraph
            dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
          />
          <Grid container alignItems="center">
            <Grid item xs={7} md={9} container direction="row">
              <Typography variant="subtitle2" className={classes.author}>
                By {post._embedded.author[0].name}
              </Typography>
              <Avatar
                className={classes.avatar}
                alt={post._embedded.author[0].name}
                src={post._embedded.author[0].avatar_urls['24']}
              />
            </Grid>
            <Grid item xs={5} md={3} container justifyContent="flex-end">
              <Button
                color="primary"
                onClick={() => showPost(post.id)}
                onMouseOver={hoverReadMore}
                onMouseOut={leaveReadMore}
              >
                Read More
                <Zoom in={isHoverReadMore}>
                  <ArrowForwardIcon />
                </Zoom>
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

Post.propTypes = {
  post: PostType,
  fetchPost: PropTypes.func,
};

const BlogPage = (props) => {
  const globalStyles = useGlobalStyles();
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const { posts, fetchPosts, fetchPost, totalPages } = props;


  useEffect(() => {
    setLoading(true);

    fetchPosts(page).then(() => {
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
    });
  }, [setLoading, fetchPosts, page]);

  const changePage = (evt, pageNum) => {
    setPage(pageNum);
  };

  return (
    <div className="content-page">
      <Container maxWidth="md">
        <Typography variant="h3" align="center" color="textPrimary" className={globalStyles.pageTitle}>Blog</Typography>
        <Card className={classes.cardContainer}>
          <CardContent>
            { posts.map(post =>
              <Post key={post.id} post={post} fetchPost={fetchPost} />
            )}
            { isLoading &&
              <Fade
                in={isLoading}
                style={{ transitionDelay: '300ms', }}
                unmountOnExit
              >
                <div className={classes.loadingContainer}>
                  <CircularProgress style={{ marginRight: '1rem' }} />
                  <Typography align="center">Loading... Please wait</Typography>
                </div>
              </Fade>
            }
          </CardContent>
        </Card>
        <Pagination
          className={classes.pagination}
          page={page}
          count={totalPages}
          onChange={changePage}
        />
      </Container>
    </div>
  );
};

BlogPage.propTypes = {
  posts: PropTypes.arrayOf(PostType),
  totalPostCounts: PropTypes.number,
  totalPages: PropTypes.number.isRequired,
  authors: PropTypes.arrayOf(AuthorType),
  fetchPosts: PropTypes.func.isRequired,
  fetchPost: PropTypes.func.isRequired,
};

BlogPage.defaultProps = {
  posts: [],
  totalPostCounts: 0,
  fetchPosts: () => {},
  fetchPost: () => {},
};

export default BlogPage;