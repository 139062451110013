import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import Copyright from './Copyright';
import Grid from '@mui/material/Grid';
import WhiteTextTypography from '../shared/WhiteTextTypography';
import { Container } from '@mui/material';
import { NavLink } from 'react-router-dom';

const footerStyles = makeStyles((theme) => ({
  msgContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 200,
  },
  typoIcon: {
    display: 'inline-flex',
    verticalAlign: 'middle',
    '& svg': {
      marginRight: '10px',
    }
  },
  footer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(4)} 0`,
    marginTop: 'auto',
  },
  copyright: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',  
    }
  },
  footerLinks: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(6),
    }
  },
  footerLink: {
    textDecoration: 'none',
    display: 'inline-block',
    position: 'relative',
    '&:after': {
      bottom: 0,
      content: '""',
      display: 'block',
      height: '2px',
      top: '1.5rem',
      left: '50%',
      position: 'absolute',
      background: 'white',
      transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
      width: 0,
    },
    '&:hover:after': {
      width: '100%',
      left: 0,
    },
    '& + &': {
      marginLeft: '1.5rem',
    },
    '& > *': {
      display: 'inline',
    },
  },
}));

const FooterLink = (props) => {
  const classes = footerStyles();
  return (
    <NavLink to={props.to} className={classes.footerLink} underline="always">
      <WhiteTextTypography variant="subtitle2">{props.children}</WhiteTextTypography>
    </NavLink>
  );
};

const Footer = () => {
  const classes = footerStyles();
  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6} className={classes.copyright}>
            <Copyright />
          </Grid>
          <Grid item xs={12} md={6} container className={classes.footerLinks}>
            <FooterLink to="/contact">Contact Us</FooterLink>
            <FooterLink to="/terms">Terms of Use</FooterLink>
            <FooterLink to="/privacy">Privacy Policy</FooterLink>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;