import makeStyles from '@mui/styles/makeStyles';

const useGlobalStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  title: {
    flexGrow: 1,
    letterSpacing: '0.1rem !important',
  },
  pageTitle: {
    marginTop: '6rem',
    marginBottom: '3rem',
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  underlineLink: {
    textDecoration: 'none',
    display: 'inline-block',
    position: 'relative',
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
    '&:after': {
      bottom: 0,
      content: '""',
      display: 'block',
      height: '2px',
      top: '1.5rem',
      left: '50%',
      position: 'absolute',
      background: theme.palette.primary.main,
      color: 'white',
      transition: 'width 0.3s ease 0s, left 0.3s ease 0s',
      width: 0,
    },
    '&:hover:after': {
      width: '100%',
      left: 0,
    },
  },
}));

export default useGlobalStyles;