import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import StyledButton from '../shared/Button/Button';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  centerContent: {
    textAlign: 'center',
    paddingBottom: '2rem',
  },
  plantImage: {
    marginRight: '2rem',
    marginBottom: '4rem',
  },
}));

const DonationSuccessPage = () => {
  const classes = useStyles();
  return (
    <div className="content-page">
      <Container className={classes.centerContent}>
        <Typography align="center" variant="h3" gutterBottom>Wow, thanks for making our day!</Typography>
        <Container maxWidth="sm">
          <Typography align="center" paragraph>
            Words can't fully express how much your support means to us. We'll be in touch soon to thank you personally for
            your contribution!
          </Typography>
          <Typography align="center" gutterBottom>A & B</Typography>
          <img className={classes.plantImage} src="/images/contribute_plant_1.svg" alt="" width="90" />
          <img className={classes.plantImage} src="/images/contribute_plant_2.svg" alt="" width="90" />
          <img className={classes.plantImage} src="/images/contribute_plant_3.svg" alt="" width="90" />
          <img className={classes.plantImage} src="/images/contribute_plant_4.svg" alt="" width="150" />
          <StyledButton to="/">Back to Home</StyledButton>
        </Container>
      </Container>

    </div>
  );
};

export default DonationSuccessPage;