import { SET_CONTRIBUTION_AMOUNT, CONTRIBUTE, CONTRIBUTE_SUCCESS, CONTRIBUTE_FAILED } from './types';

const initialState = {
  amount: 0,
  status: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTRIBUTION_AMOUNT:
      return {
        ...state,
        amount: action.amount,
      };
    case CONTRIBUTE:
      return {
        ...state,
        amount: action.amount,
        status: 'contributing',
      };
    case CONTRIBUTE_SUCCESS:
      return {
        ...state,
        status: 'success',
      };
    case CONTRIBUTE_FAILED:
      return {
        ...state,
        status: 'failed',
      };
    default:
      return state;
  }
};

export default reducer;