import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';

import useGlobalStyles from '../../GlobalStyles';

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
  }
}));

const ErrorPage = () => {
  const history = useNavigate();
  const globalStyles = useGlobalStyles();
  const classes = useStyles();

  return (
    <div className="content-page">
      <Container>
        <Typography variant="h3" align="center" className={globalStyles.pageTitle} gutterBottom>Oops...</Typography>
        <Container maxWidth="sm">
          <Typography>
            This page is an invalid link. Click <Link className={classes.link} onClick={() => history.goBack()}>here</Link> to return to previous page or visit our main page <Link href="/">here</Link>.
          </Typography>
        </Container>
      </Container>
    </div>
  );
};

export default ErrorPage;