import React from 'react';
import PropTypes from 'prop-types';
import StarIcon from '@mui/icons-material/Star';
import HalfFilledStarIcon from '@mui/icons-material/StarHalf';
import EmptyStarIcon from '@mui/icons-material/StarBorder';

const Ratings = (props) => {
  const {
    ratings,
    totalRatings,
  } = props;

  return (
    <div>
      {Array(totalRatings).fill().map((rating, i) => {
        const ratingIndex = i + 1;
        if (ratingIndex > ratings && ratingIndex !== Math.ceil(ratings)) {
          return (<EmptyStarIcon key={`rating-${i}`} color="secondary" />);
        } else if (ratingIndex > ratings && ratingIndex === Math.ceil(ratings)) {
          return (<HalfFilledStarIcon key={`rating-${i}`} color="secondary" />);
        }
        return (<StarIcon key={`rating-${i}`} color="secondary" />);
      })}
    </div>
  );
};

Ratings.propTypes = {
  ratings: PropTypes.number.isRequired,
  totalRatings: PropTypes.number,
};

Ratings.defaultProps = {
  totalRatings: 5,
};

export default Ratings;