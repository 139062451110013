import React from 'react';
import Typography from '@mui/material/Typography';
import useGlobalStyles from '../../GlobalStyles';
import Container from '@mui/material/Container';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';

const FaqPage = () => {
  const globalStyles = useGlobalStyles();
  return (
    <div className="content-page">
      <Typography variant="h3" align="center" className={globalStyles.pageTitle}>FAQ</Typography>
      <Container maxWidth="md">
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
            <Typography style={{ fontWeight: 'bolder' }}>How does Pockett differ from other personal finance apps (e.g. expense trackers)?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Pockett supports you in having a more holistic view of your finances and provides a user-friendly platform to easily visualise
              and analyse your consolidated financial data in any way you like. Pockett can also be used for expense tracking, whether automatically
              via your credit/debit card transactions, or via manual entry. We are constantly working on expanding Pockett's capabilities. You can
              check out what we're working on at the moment and vote for new features here.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography style={{ fontWeight: 'bolder' }}>
              Why should I use Pockett instead of apps developed by well-established financial institution (e.g. banking apps)?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <Typography paragraph>
                  Pockett's dashboard and charts are highly customizable which allows you to slice and dice your financial data in any way you like.
                  This was the primary reason why we created Pockett - we simply weren't satisfied with the default charts and limited options provided
                  by the banking apps.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography paragraph>
                  Pockett also helps you consolidate your finances across banks and countries. Unlike banking apps, Pockett does this without ever
                  storing your financial data. All the data that you see in the app are stored locally on your device. This ensures that your data always
                  remains private to you and gives you complete control over how your sensitive financial information is shared.
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Container>
    </div>
  );
};

export default FaqPage;