import { MESSAGE_SENT_FAILED, MESSAGE_SENT, SENDING_MESSAGE } from './types';

const initialState = {
  sendingMessage: false,
  messageSent: false,
  errorMessage: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_SENT:
      return {
        ...state,
        sendingMessage: false,
        messageSent: true,
      };
    case MESSAGE_SENT_FAILED:
      return {
        ...state,
        sendingMessage: false,
        messageSent: false,
        errorMessage: action.errorMessage.toString(),
      };
    case SENDING_MESSAGE:
      return {
        ...state,
        sendingMessage: true,
      };
    default:
      return state;
  }
};

export default reducer;