import React from 'react';
import { Card, CardContent, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import useGlobalStyles from '../../GlobalStyles';
import Link from '@mui/material/Link';

const styles = makeStyles((theme) => ({
  pageCardStyle: {
    padding: '3.5rem',
    marginBottom: '3rem',
    [theme.breakpoints.down('md')]: {
      padding: '1rem',
    },
    '& p': {
      marginBottom: '2rem',
    },
    '& li': {
      marginTop: '1rem',
    },
  },
}));

const PrivacyPage = (props) => {
  const classes = styles();
  const globalStyles = useGlobalStyles();

  return (
    <div className="content-page">
      <Container maxWidth="md">
        <Typography variant="h3" gutterBottom align="center" className={globalStyles.pageTitle} color="textPrimary">Privacy Policy</Typography>
        <Card className={classes.pageCardStyle}>
          <CardContent>
            <Typography variant="h5" color="textPrimary" gutterBottom>Welcome to Pockett!</Typography>
            <Typography paragraph>Your privacy is very important to us. This Privacy Policy covers how we collect, use,
              and share information in connection with your access and use of the Pockett application ("App").
              When you use the App, you are consenting to the terms of this Privacy Policy.</Typography>

            <Typography variant="h5" color="textPrimary" gutterBottom>What information We Collect</Typography>
            <Typography paragraph>There are two ways we collect information about you: when you give it to us, and with automatic technologies. Allow us to explain.</Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Information You Provide To Us</Typography>
            <Typography paragraph>The App collects the information you choose to provide. An example includes information
              you provide to us when registering for an account.</Typography>
            <Typography component={'div'} paragraph>
              The types of information you provide to us fits into two general categories:
              <ul>
                <li><strong>Personal Information:</strong> This is information that identifies you or others personally,
                  such as your name and email address.</li>
                <li><strong>Non-Personal Information:</strong> This is information about you that doesn't identify you
                  personally, like your country of residence.</li>
              </ul>
            </Typography>
            <Typography paragraph>
              When we combine information that isn't Personal Information (like Non-Personal Information or Usage
              Information, which we explain below) with Personal Information, we treat it like Personal Information for
              as long as it is combined.
            </Typography>

            <Typography variant="h6" color="textPrimary" gutterBottom>Information from Automatic Technologies</Typography>
            <Typography component={'div'} paragraph>In addition to information you provide to us, the App automatically collects certain
              information about how you access and interact with the platform (“Usage Information”). This Usage
              Information is a key part of how we improve your App experience. There are two types of Usage Information
              the App may collect:
              <ul>
                <li><strong>Device Information.</strong> This is information about how you access our Services, such as the operating system, hardware model, application or browser type and version, and unique identifiers associated with your device. We may also integrate with built-in device features (e.g., when you turn on fingerprint access for your Pockett account).</li>
                <li><strong>Log Information.</strong> There are certain pieces of information we automatically collect in system logs or similar files about how you interact with our Services, such as which features you visit or click on. This Log Information may also include things like your IP address, browser or device configuration, date and time of access, cookie information and device crash logs.</li>
              </ul>
            </Typography>

            <Typography variant="h5" color="textPrimary" gutterBottom>How We Use the Information We Collect</Typography>
            <Typography component={'div'} paragraph>Your privacy is our top priority. We will use your information for the following purposes:
              <ul>
                <li>To identify you on login;</li>
                <li>To provide you with the App’s functionality;</li>
                <li>To request a password reset;</li>
                <li>To contact you about your Pockett account;</li>
                <li>To respond to your inquiries and fulfil your requests;</li>
                <li>To send administrative information to you, such as information regarding the App and changes to our terms,
                  including through push notifications and email; and</li>
                <li>To send you marketing communications that we believe may be of interest to you, including through push
                  notifications and email.</li>
              </ul>
            </Typography>

            <Typography variant="h5" color="textPrimary" gutterBottom>Account Deletion/Requests to Delete Personal Data</Typography>
            <Typography paragraph>
              You may stop all collection of information by the App by uninstalling the App. You may use the standard uninstall process
              as may be available as part of your mobile device or via the mobile application marketplace or network.
            </Typography>
            <Typography paragraph>
              While using the App, the App allows you to edit your data and delete your account. You may do this in the
              Profile section of the App.
            </Typography>

            <Typography variant="h5" color="textPrimary" gutterBottom>Disclosure to Third Parties</Typography>
            <Typography component={'div'} paragraph>We do not share your information with third parties, except for the few exceptions below.
              <ul>
                <li><strong>Where disclosure is required by law.</strong> We reserve the right to share information about
                  you if disclosure of such information is reasonably necessary to satisfy any applicable law, regulation,
                  legal process or enforceable governmental request, and as permitted by law.
                </li>
                <li><strong>As Part of a Corporate Change.</strong> We may disclose and transfer information about you to
                  a third party as part of, or in preparation for, a change of control, restructuring, corporate change,
                  or sale or transfer of assets. If such a business transfer results in a material change in the treatment
                  of your Personal Information, you will be notified by e-mail (using the primary email address on your
                  account) or by a prominent notice on our site.</li>
              </ul>
            </Typography>

            <Typography variant="h5" color="textPrimary" gutterBottom>Children's Privacy</Typography>
            <Typography paragraph>We will not knowingly collect personal information from children under 13. If we learn
              that we have collected personal information from a child under 13, we will promptly delete this information.
              Parents or guardians can contact us at <Link href="mailto:support@pockett.app">support@pockett.app</Link>.</Typography>

            <Typography variant="h5" color="textPrimary" gutterBottom>Third Party Sites and Services</Typography>
            <Typography paragraph>The App may contain links to third-party websites or services. We are not responsible
              for the information collected by those third parties and we encourage you to read their privacy policy
              before submitting any personal information to them.</Typography>

            <Typography variant="h5" color="textPrimary" gutterBottom>Changes to This Privacy Policy</Typography>
            <Typography paragraph>We seek to continually improve our handling of Personal Information. As a result, we
              may change this Privacy Policy from time to time and publish an updated version on our Website.<br />Your
              use of the App following these changes means that you accept the revised Privacy Policy.</Typography>
            <Typography paragraph>If you have any questions regarding our Privacy Policy, please contact us at &nbsp;
              <Link href="mailto:support@pockett.app">support@pockett.app.</Link>
            </Typography>
            <Typography>Last Updated: 19 Jun 2022</Typography>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default PrivacyPage;