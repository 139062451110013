import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import { Menu, MenuItem, useMediaQuery, useScrollTrigger, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink, useLocation } from 'react-router-dom';

import StyledButton from '../shared/Button/Button';
import useGlobalStyles from '../../GlobalStyles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const useStyles = makeStyles((theme) => ({
  grow: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
  navigationLinks: {
    display: 'flex',
    alignItems: 'center',
    '& > a': {
      textDecoration: 'none',
      marginRight: theme.spacing(4),
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontFamily: 'Montserrat',
      letterSpacing: '0.08rem',
      [theme.breakpoints.down('md')]: {
        marginRight: theme.spacing(2),
        fontSize: '12px',
      }
    },
  },
  mobileNavContainer: {},
  root: {
    backgroundColor: theme.palette.background.default,
    borderBottom: 'solid 1px transparent',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    transition: 'border-bottom 500ms, box-shadow 500ms',
  },
  navScroll: {
    borderBottom: 'solid 1px #ccc',
    boxShadow: '0 2px 4px #ccc',
  },
  noUnderline: {
    '&:hover:after': {
      width: 0,
    },
    '&:after': {
      height: 0,
      display: 'none',
      transition: 'none',
      width: 0,
    }
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  logoText: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: '1.3rem',
    fontFamily: 'Montserrat',
    fontWeight: 'normal',
    letterSpacing: '1.2px',
    transition: 'all 0.4s',
    marginLeft: '2px',
    '&:after': {
      transition: 'none',
      height: 0,
    },
    '&:hover': {
      textShadow: `0 0 10px ${theme.palette.secondary.main}`,
    }
  },
}));

const ElevationScroll = (props) => {
  const {
    children,
    //window,
  } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    //target: window ? window() : undefined,
    target: undefined,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    /*style: {
      backgroundColor: trigger ? 'white' : 'rgba(255,255,255,0.65)',
    },*/
  });
};

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const Header = (props) => {
  const classes = useStyles(props);
  const globalStyles = useGlobalStyles();
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [currLoc, setCurrLoc] = React.useState(location.pathname);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [useScrollStyle, setUseScrollStyle] = React.useState(false);

  const handleScroll = () => {
    if (window.scrollY > 140) {
      if (!useScrollStyle) {
        setUseScrollStyle(true);
      }
    } else {
      if (useScrollStyle) {
        setUseScrollStyle(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        if (location.pathname === currLoc) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        } else {
          elem.scrollIntoView({ block: 'center', inline: 'center' });
        }
      }
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    setCurrLoc(location.pathname);
  }, [location, currLoc]);

  const handleNavMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNavMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
        <AppBar
          position="fixed"
          elevation={0}
          className={classes.root}
        >
          <Toolbar>
            <NavLink to="/" style={{ textDecoration: 'none' }}>
              <div className={classes.logo}>
                <img
                  src="/images/logo/PockettLogo.svg"
                  className={props.iconClass}
                  height="35rem"
                  alt="Pockett Logo"
                />
                <div className={classes.logoText}>Pockett</div>
              </div>
            </NavLink>
            <div className={classes.grow}/>
            { isDesktop ?
              <div className={classes.navigationLinks}>
                <NavLink to="/ourstory" className={globalStyles.underlineLink}>
                  Our Story
                </NavLink>
                <a
                  href="https://support.pockett.app"
                  target="_blank"
                  rel="noreferrer"
                  className={globalStyles.underlineLink}
                >
                  Support
                </a>
                <a
                  href="https://app.loopedin.io/pockett-roadmap"
                  target="_blank"
                  rel="noreferrer"
                  className={globalStyles.underlineLink}
                >
                  Vote for features
                </a>
                <NavLink to="/blog" className={globalStyles.underlineLink}>Blog</NavLink>
                <StyledButton to="/#downloadSection" style={{ color: 'white' }}>Get the free app</StyledButton>
              </div> :
              <div className={classes.mobileNavContainer}>
                <IconButton
                  aria-controls="mobile-nav-menu"
                  aria-haspopup="true"
                  onClick={handleNavMenuClick}
                  size="large"
                  color="primary"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="mobile-nav-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleNavMenuClose}
                  color="textPrimary"
                >
                  <MenuItem component={NavLink} color="textPrimary" to="/ourstory" onClick={handleNavMenuClose}>Our Story</MenuItem>
                  <MenuItem component="a" href="https://support.pockett.app" target="_blank">Support</MenuItem>
                  <MenuItem component="a" href="https://app.productstash.io/pockett-roadmap#/roadmap" target="_blank">Vote for features</MenuItem>
                  <MenuItem component={NavLink} to="/blog" onClick={handleNavMenuClose}>Blog</MenuItem>
                  <MenuItem component={NavLink} to="/#downloadSection" onClick={handleNavMenuClose}>Get the free app</MenuItem>
                </Menu>
              </div>
            }
          </Toolbar>
        </AppBar>
    </div>
  );
};

Header.propTypes = {
  iconClass: PropTypes.string,
  titleClass: PropTypes.string,
};

export default Header;