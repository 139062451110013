import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';

export const socialMediaLinks = [
  {
    id: 'link',
    link: '#',
    tooltip: 'Copy Link',
    icon: LinkIcon,
    className: 'link'
  },
  {
    id: 'twitter',
    link: 'http://www.twitter.com/share?url=#url',
    tooltip: 'Share to Twitter',
    icon: TwitterIcon,
    className: 'twitter',
  },
  {
    id: 'facebook',
    link: 'https://www.facebook.com/sharer/sharer.php?u=#url',
    tooltip: 'Share to Facebook',
    icon: FacebookIcon,
    className: 'facebook',
  },
  {
    id: 'email',
    link: 'mailto:?subject=Hello&body=#url',
    tooltip: 'Email link',
    icon: EmailIcon,
    className: 'email',
  }
];