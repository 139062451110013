import { NOTIFY_DISMISS, NOTIFY_FAILED, NOTIFY_ME, NOTIFY_SUCCESS } from './types';

const initialState = {
  sendingNotify: false,
  notifyResult: false,
  errorMessage: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFY_ME:
      return {
        ...state,
        sendingNotify: true,
      };
    case NOTIFY_SUCCESS:
      return {
        ...state,
        sendingNotify: false,
        notifyResult: true,
      };
    case NOTIFY_FAILED:
      return {
        ...state,
        sendingNotify: false,
        notifyResult: false,
        errorMessage: `${action.error}`,
      };
    case NOTIFY_DISMISS:
      return {
        ...state,
        notifyResult: false,
        errorMessage: '',
      };
    default:
      return state;
  }
};

export default reducer;