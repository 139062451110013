import axios from 'axios';
import {
  FETCH_POSTS,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_FAILED,
  FETCH_POST,
  FETCH_POST_SUCCESS,
  FETCH_POST_FAILED
} from './types';
//import { sleeper } from '../../utils/sleeper';

const baseUrl = 'https://blog.pockett.app';

export const fetchPosts = (page = 1) => async dispatch => {
  dispatch({
    type: FETCH_POSTS,
  });
  return axios.get(`${baseUrl}/wp-json/wp/v2/posts?per_page=10&_embed=1&page=${page}`, {})
    //.then(sleeper(400000))
    .then((response) => {
      console.log('see posts response', response.data, response);
      dispatch({
        type: FETCH_POSTS_SUCCESS,
        totalPages: parseInt(response.headers['x-wp-totalpages']),
        totalPostCounts: parseInt(response.headers['x-wp-total']),
        posts: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_POSTS_FAILED,
        errorMessage: error,
      });
    });
};

export const fetchPost = (postId) => async dispatch => {
  dispatch({
    type: FETCH_POST,
  });
  return axios.get(`${baseUrl}/wp-json/wp/v2/posts/${postId}?_embed=1`, {})
    .then((response) => {
      console.log('see post', response.data);
      dispatch({
        type: FETCH_POST_SUCCESS,
        post: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_POST_FAILED,
        errorMessage: error,
      });
    });
};

const actions = {
  fetchPosts,
  fetchPost,
};

export default actions;